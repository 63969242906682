Converia.Core.module('Converia.Core.CompanyProposal', function () {
    var CompanyProposal = function (ele, companyList) {
        $(ele).attr("autocomplete", "off");
        $(ele).typeahead({
            source: companyList,
            minLength: 3,
            items: 9999
        });

        fixChromeProblems(ele);
    };

    var fixChromeProblems = function (ele) {
        var form = $(ele).closest('form');
        var chromeWorkaround = '<input type="text" id="PreventChromeAutocomplete" name="PreventChromeAutocomplete" autocomplete="address-level4" style="display: none;">';
        form.prepend(chromeWorkaround);
    };

    return CompanyProposal;
});