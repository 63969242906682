/**
 * Converia.Core.module
 */
Converia.Core.module('Converia.Core.Modal', function() {
    var closeOnCreation = false;

    /**
     * get browsers scrollbar width to prevent horizontal jumping
     * when body is set to overflow: hiddden
     *
     * @returns {number}
     */
    // var scrollbarWidth = (function  () {
    //     var $outer = $('<div>').css({
    //         visibility: 'hidden',
    //         width: 100,
    //         overflow: 'scroll'
    //     }).appendTo('body');
    //
    //     var widthWithScroll = $('<div>').css({
    //         width: '100%'
    //     }).appendTo($outer).outerWidth();
    //
    //     $outer.remove();
    //
    //     return 100 - widthWithScroll;
    // })();

    /**
     * Modal
     * 
     * @param modalStack
     * @constructor
     */
    var Modal = function(modalStack) {
        this.ele            = null;
        this.options        = {};
        this.onHideClose    = true;
        this.closeDirectly  = false;
        this.modalStack     = null;
        this.showModal      = false;

        if (modalStack) {
            this.modalStack = modalStack;
            this.modalStack.push(this);
        }
    };

    /**
     * Modal.prototype.open
     * 
     * @param ele
     * @param options
     */
    Modal.prototype.open = function(ele, options) {
        var defaults = {
            preventClose: false
        };

        if (typeof(ele) == 'string') {
            var dialogContent = ele;
        } else {
            var dialogContent = $(ele).data('modal');
        }

        var contentEle = $(dialogContent);
        this.options = $.extend(defaults, options);
        this.ele = this.createOverlay(contentEle, this.options.addClass ? this.options.addClass : '');

        var proxy = $.proxy(this.onElementHide, this);

        $(this.ele).on('hidden', proxy);

        $(this).trigger('before-open', this.ele);

        this.show();
    };

    /**
     * Modal.prototype.onElementHide
     * 
     * @param event
     * @returns {boolean}
     */
    Modal.prototype.onElementHide = function(event) {
        if (event.target !== this.ele) {
            return true;
        }

        if (this.onHideClose) {

            //prevent recursion because close triggers hide and that calls close
            if (this.closeDirectly) {
                return true;
            }

            if (this.options.preventClose) {
                return false;
            }

            this.close();

            return true;
        } else {
            this.showModal = false;

            $(this).trigger('hidden');//do not name an event as method
            $('body')
                .removeClass('overlay-open');
                // .css({
                //     'padding-right': 0
                // });
            return true;
        }
    };

    /**
     * Modal.prototype.getOverlayType
     * 
     * @returns {string}
     */
    Modal.prototype.getOverlayType = function() {
        var overlayType = 'modal';
        return overlayType;
    };

    /**
     * Modal.prototype.createOverlay
     * 
     * @param contentEle
     * @param wrapperAddClass
     * @returns {*|jQuery}
     */
    Modal.prototype.createOverlay = function(contentEle, wrapperAddClass) {
        closeOnCreation = true; //do not remove from stack
        this.close(true);
        closeOnCreation = false;

        var overlayType = this.getOverlayType();
        var tpl =
            '<div class="overlay overlay-' + overlayType + ' overlay-hide fade ' + wrapperAddClass + '" aria-hidden="true">' +
            '	<div class="overlay-body">' +
            ((!this.options.preventClose) ? '<span class="overlay-close" data-dismiss="modal" aria-hidden="true"><span class="'+Converia.Core.Icon.cssClassPrefix+'-icon '+Converia.Core.Icon.cssClassPrefix+'-icon-close"></span></span>' : '') +
            '		<div class="overlay-inner">' +
            '		</div>' +
            '	</div>' +
            '</div>'
        ;

        var ele = $(tpl).appendTo('body').get(0);

        $(ele)
            .find('.overlay-inner')
            .append(contentEle);

        return ele;
    };

    /**
     * Modal.prototype.hide
     * 
     * Hide is needed for stack usage, normal modals should be close
     * @internal
     */
    Modal.prototype.hide = function() {
        this.onHideClose = false;

        if (this.ele) {
            $(this.ele).modal('hide');
        }
    };

    /**
     * Modal.prototype.show
     */
    Modal.prototype.show = function() {
        this.onHideClose = true;

        if (this.ele) {
            $(this).trigger('before-shown'); //before shown

            $(this.ele).modal({
                show: true,
                keyboard: false
            });

            this.showModal = true;

            // check body width before opening the modal
            var width1 = $('body').outerWidth(true);
            
            // set overlay class
            $('body').addClass('overlay-open');
            
            // check body width again
            var width2 = $('body').outerWidth(true);

            // set the difference as margin and padding
            if (width2 != width1) {
                $('body').css('marginRight', (width2 - width1) + 'px');
                $('.sticky-container .sticky').css('paddingRight', (width2 - width1) * 2 + 'px');
            }

            $(this).trigger('shown'); //do not name an event as method
        }
    };

    /**
     * Modal.prototype.forceClose
     */
    Modal.prototype.forceClose = function() {
        this.options.preventClose = false;
        this.close();
    };

    /**
     * Modal.prototype.close
     * 
     * @returns {boolean}
     */
    Modal.prototype.close = function() {
        if (this.options.preventClose) {
            return false;
        }

        if (this.ele) {
            $(this).trigger('before-closed');//do not name an event as method

            this.onHideClose = true;
            this.closeDirectly = true;

            $(this.ele).modal('hide');
            $(this.ele).off('hidden');
            $(this.ele).remove();

            this.ele = null;
            this.showModal = false;

            // remove overlay class
            // remove style attr from body
            $('body')
                .removeClass('overlay-open')
                .removeAttr("style");

            // remove padding styles from sticky elements
            $('.sticky-container .sticky').css('paddingRight', '');

            $(this).trigger('closed'); //do not name an event as method

            this.closeDirectly = false;
        }

        if (!closeOnCreation && this.modalStack) {
            this.modalStack.remove(this);
        }

        return true;
    };

    /**
     * Modal.prototype.isShown
     * 
     * @returns {boolean}
     */
    Modal.prototype.isShown = function() {
        return this.showModal;
    };

    /**
     * Modal.prototype.getModalDomElement
     * 
     * @returns {null|*}
     */
    Modal.prototype.getModalDomElement = function() {
        return this.ele;
    };

    return Modal;
});
