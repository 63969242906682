/**
 * Converia VSB LoginHandler
 *
 * Converia.Core.module
 */
Converia.Core.module('Converia.VSB.LoginHandler', function () {
    /**
     * LoginHandler
     *
     * @param conferenceConfig
     * @constructor
     */
    var LoginHandler = function (conferenceConfig) {
        this.loginState = false;
        this.config = conferenceConfig;
        var self = this;

        this.modalReference = new Converia.Core.StructuredModal();
        $(this.modalReference).on('closed', function () {
            self.onClose();
        });

        this.url = conferenceConfig.urls.login;
    };

    /**
     * LoginHandler.prototype.onClose
     */
    LoginHandler.prototype.onClose = function () {
        $(this).trigger('closed');
    };

    /**
     * LoginHandler.prototype.setIsLogin
     */
    LoginHandler.prototype.setIsLogin = function () {
        this.loginState = true;
        $(this).trigger('is-logged-in');
    };

    /**
     * LoginHandler.prototype.isLoggedIn
     * 
     * @returns {boolean}
     */
    LoginHandler.prototype.isLoggedIn = function () {
        return this.loginState;
    };

    /**
     * LoginHandler.prototype.show
     */
    LoginHandler.prototype.show = function () {
        var self = this;

        this.modalReference.open(
            this.config.loginForm[0],
            this.config.loginForm[1],
            ''
        );

        $(this.modalReference.getModalDomElement()).find('button[data-login-btn]').on('click', function (event) {
            self.onLoginClick();
            event.preventDefault();
        });
    };

    /**
     * LoginHandler.prototype.close
     */
    LoginHandler.prototype.close = function () {
        this.modalReference.close();
    };

    /**
     * LoginHandler.prototype.checkLogin
     */
    LoginHandler.prototype.checkLogin = function () {
        var self = this;

        $.ajax({
            type: "POST",
            url: this.url.replace(new RegExp(/__dummy_action__/, 'g'), 'get-login-state'),
            data: {},
            success: function (result) {
                if (result.isLoggedIn) {
                    self.setIsLogin();
                }
            },
            error: function () {
                self.handleError();
            },
            dataType: 'json'
        });
    };

    /**
     * LoginHandler.prototype.refreshLogin
     */
    LoginHandler.prototype.refreshLogin = function () {
        this.loginState = false;
        this.show();
    };

    /**
     * LoginHandler.prototype.handleError
     */
    LoginHandler.prototype.handleError = function () {
    };

    /**
     * LoginHandler.prototype.onLoginClick
     */
    LoginHandler.prototype.onLoginClick = function () {
        var self = this;
        var userName = $(this.modalReference.getModalDomElement()).find('#user_name').val();
        var password = $(this.modalReference.getModalDomElement()).find('#password').val();

        $(this.modalReference.getModalDomElement()).find('.help-error').remove();
        $(this).trigger('start-login');

        $.ajax({
            type: "POST",
            url: this.url.replace(new RegExp(/__dummy_action__/, 'g'), 'login'),
            data: {
                userName: userName,
                password: password
            },
            success: function (result) {
                if (result.isLoggedIn) {
                    self.setIsLogin();
                    self.close();
                } else {
                    if (result.errors.userName) {
                        $('#user_name').after('<span class="help-block help-error">' + result.errors.userName + '</span>');
                    }
                    if (result.errors.password) {
                        $('#password').after('<span class="help-block help-error">' + result.errors.password + '</span>');
                    }
                }
                $(self).trigger('end-login');
            },
            error: function () {
                self.handleError();
                self.close;
                $(self).trigger('end-login');
            },
            dataType: 'json'
        });
    };

    return LoginHandler;
});