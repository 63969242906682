/**
 * Converia Modal: iFrame
 */
Converia.Core.module('Converia.Core.IframeModal', function() {

    /**
     * generateModalContentString
     * 
     * @param title
     * @param content
     * @returns {string}
     */
    var generateModalContentString = function (title, content) {
        var tplHeader = '<div class="overlay-header" role="heading">modal.header</div>';
        var tplContent = '<div class="overlay-content">modal.content</div>';
        //var tplFooter = '<div class="overlay-footer">modal.footer</div>';
        var contentHtmlString = '';

        if (title) {
            contentHtmlString += tplHeader.replace(/modal\.header/, title);
        }

        if (content) {
            contentHtmlString += tplContent.replace(/modal\.content/, content);
        }

        // if (footer) {
        //     contentHtmlString += tplFooter.replace(/modal\.footer/, footer);
        // }

        return contentHtmlString;
    };

    /**
     * Modal
     * 
     * @param modalStack
     * @constructor
     */
    var Modal = function(modalStack) {
        Converia.Core.Modal.call(
            this,
            modalStack
        );
    };

    /**
     * Modal.prototype
     * 
     * @type {Converia.Core.Modal}
     */
    Modal.prototype = Object.create(
        Converia.Core.Modal.prototype,
        {}
    );

    /**
     * Modal.prototype.open
     * 
     * @param url
     * @param title
     * @param options
     */
    Modal.prototype.open = function(url, title, options) {
        height = $(window).height() - 159;// 2*50 border + 59 for header
        width = $(window).width() - 30;

        var iframe = '<iframe src="' + url + '" height="' + (height) + '" width="' + (width) + '">';
        var innerContent = generateModalContentString(title, iframe);

        Converia.Core.Modal.prototype.open.call(
            this,
            innerContent,
            options
        );
    };

    /**
     * Modal.prototype.getOverlayType
     * 
     * @returns {string}
     */
    Modal.prototype.getOverlayType = function() {
        var overlayType = 'modal overlay-modal-iframe';

        return overlayType;
    };

    return Modal;
});