Converia.Core.module('Converia.Core.Dialog', function () {
    var Dialog = function (title, content, buttons, options, modalStack) {
        Converia.Core.StructuredModal.call(this, modalStack);

        options = $.extend({
            preventClose: true
        }, options);

        var footer = this.createButtonFooter(buttons);
        this.open(title, content, footer, options);
        var handler = $.proxy(this.onClickAction, this);
        $(this.getModalDomElement()).on('click', '[data-click-action]', handler);
    };

    Dialog.prototype = Object.create(Converia.Core.StructuredModal.prototype, {});

    Dialog.prototype.onClickAction = function (event) {
        var button = event.target;
        var actionKey = $(button).data('click-action');

        $(this).trigger('dialog-action', {
            actionKey: actionKey,
            originalEvent: event
        });
	};

    Dialog.prototype.createButtonFooter = function (buttons) {
        var footerHtml = '';
        var buttonTemplate = '<button type="button" class="button button.class" data-click-action="button.name" button.action button.dismiss>button.label</button>';

        for (var i = 0,len = buttons.length; i < len; i++) {
            var button = buttons[i];
            var dismiss = '';
    
            if (button.name === 'abort') {
                dismiss = ' data-dismiss="modal" ';
            }
            
            footerHtml += buttonTemplate
                .replace(/button\.class/, button.class ? button.class : '')
                .replace(/button\.name/, button.name)
                .replace(/button\.action/, button.action ? 'data-action-url="'+encodeURI(button.action)+'"' : '')
                .replace(/button\.label/, button.label)
                .replace(/button\.dismiss/, dismiss);
        }

        return '<div class="button-control button-control-spread">' + footerHtml + '</div>';
    };

    Dialog.prototype.getOverlayType = function() {
        var overlayType = 'dialog';

        return overlayType;
    };

    return Dialog;
});