/**
 * Converia Registration CounterModeHandler
 */

Converia.Core.module('Converia.Registration.CounterModeHandler', function () {

    /**
     * CounterModeHandler
     * 
     * @param config
     * @constructor
     */
    var CounterModeHandler = function (config) {
        this.config = config;
        this.printJobs = [];
        this.printAdapter = new Converia.Core.PrintAdapter();

        if (config.invoice) {
            this.printJobs.push([
                'default',
                config.invoice,
                config.invoice_success,
                config.invoice_error,
                false
            ]);
        }

        if (config.confirmation) {
            this.printJobs.push([
                'default',
                config.confirmation,
                config.confirmation_success,
                config.confirmation_error,
                false
            ]);
        }

        if (config.badge && config.badge_printer) {
            this.printJobs.push([
                config.badge_printer,
                config.badge.content,
                config.badge_success,
                config.badge_error,
                true
            ]);
        }
    };

    /**
     * CounterModeHandler.prototype.markBadgePrinted
     */
    CounterModeHandler.prototype.markBadgePrinted = function () {
        var url = this.config.badge_printed_url;
        var self = this;
        var request = new Converia.Core.AjaxRequest(url, {});
 
        request.send().then(
            function (result) {
                self.run();
            }
        ).else(
            function (result) {
                $(self).trigger('show-messages', {
                    messages: [{
                        type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                        message: 'Connection error during badge sync.'
                    }]
                });
            }
        );
    };

    /**
     * CounterModeHandler.prototype.run
     */
    CounterModeHandler.prototype.run = function () {
        var self = this;

        if (this.printJobs.length) {
            var job = this.printJobs.shift();

            this.printAdapter.print(job[0], 'pdf', job[1]).then(
                function (result) {
                    $(self).trigger('show-messages', {
                        messages: [{
                            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_SUCCESS,
                            message: job[2]
                        }]
                    });

                    if (job[4]) {
                        self.markBadgePrinted();
                    } else {
                        self.run();
                    }
                }
            ).else(
                function (result) {
                    $(self).trigger('show-messages', {
                        messages: [{
                            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                            message: job[3]
                        }]
                    });
                }
            );
        } else {
            this.startRedirectInterval();
        }
    };

    /**
     * CounterModeHandler.prototype.startRedirectInterval
     */
    CounterModeHandler.prototype.startRedirectInterval = function () {
        var url = this.config.redirect_url;

        $('#redirectInfo').removeClass('hide');

        var timer = 10;
        var timerId = 0;
        var position = {x: 0, y: 0}

        $(document).ready(function () {
            $(document).on("mousemove", function (event) {
                if (position.x != event.clientX || position.y != event.clientY) {
                    timer = 10;
                }

                position = {
                    x: event.clientX,
                    y: event.clientY
                };
            });
        });

        timerId = window.setInterval(function () {
            timer -= 1;
            $('#redirect_time').text(timer);

            if (timer <= 0) {
                timer = 10;
                window.clearInterval(timerId);
                document.location.href = url;
            }
        }, 1000);
    };

    return CounterModeHandler;
});