/**
 * Converia Registration FormHandler
 */

Converia.Core.module('Converia.Registration.FormHandler', function () {

    /**
     * FormHandler
     * 
     * @constructor
     */
    var FormHandler = function () {
        this.waitingForFormFinish = false;
    };

    /**
     * FormHandler.prototype.onAjaxFormSubmit
     * 
     * @param event
     */
    FormHandler.prototype.onAjaxFormSubmit = function (event) {
        event.preventDefault();

        var element = event.target;
        var success = $.proxy(this.onFormSuccess, this, element);
        var error = $.proxy(this.onFormError, this, element);
        var submitTimeout = $(element).data('submit-timeout');

        if (submitTimeout) {
            window.clearTimeout(submitTimeout);
            $(element).data('submit-timeout', null);
        }

        if (this.waitingForFormFinish) {
            $(this).trigger('show-messages', {
                messages: [{
                    type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                    message: 'Unfinished request running.'
                }]
            });

            return;
        }

        var url = $(element).data('ajax-form');

        // save data into formdata object to use multipart form submit and handle files
        params = new FormData(element);
        new Converia.Core.AjaxRequest(url, params, {})
            .then(success)
            .else(error)
            .sendAsFormData();

        this.waitingForFormFinish = true;
        $(this).trigger('in-process');
    };

    /**
     * FormHandler.prototype.onFormSuccess
     * 
     * @param oldForm
     * @param res
     */
    FormHandler.prototype.onFormSuccess = function (oldForm, res) {
        $(this).trigger('in-process', {end: true});
        this.waitingForFormFinish = false;

        if (res.messages) {
            $(this).trigger('show-messages', {
                messages: res.messages
            });
        }

        if (res.closeDialogs) {
            $(this).trigger('close-all-dialogs', {});
        }

        if (res.redirect) {
            document.location = res.redirect;
        }

        if (res.content) {
            var content = $(res.content).filter('[data-converia-module]').get(0);

            $('[data-converia-module]').replaceWith(content);

            if ($(oldForm).data('overlay-form')) {
                $(this).trigger('close-modal');
            }

            $(this).trigger('refresh-listener', {
                element: content
            });
        }
    };

    /**
     * FormHandler.prototype.onFormError
     * 
     * @param oldForm
     * @param res
     */
    FormHandler.prototype.onFormError = function (oldForm, res) {
        $(this).trigger('in-process', {
            end: true
        });

        this.waitingForFormFinish = false;

        if (res.connectionError) {
            $(this).trigger('show-messages', {
                messages: [{
                    type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                    message: 'Connection to server not possible.'
                }]
            });

            return;
        }

        if (res.closeDialogs) {
            $(this).trigger('close-all-dialogs', {});
        }

        if (res.dialog) {
            $(this).trigger('open-dialog', {
                dialog: res.dialog,
                dialogOptions: res.dialogOptions
            });
        }

        if (res.messages) {
            $(this).trigger('show-messages', {
                messages: res.messages
            });
        }

        if (res.content) {
            var content = $.trim(res.content);
            var contentDom = $.parseHTML(content);
            var form = $(contentDom).filter('form');

            if (form.length === 0) {
                form = $(contentDom).find('form');
            }

            if (form.length > 0) {
                $(oldForm).replaceWith(form);
                $(this).trigger('refresh-listener', {
                    element: form
                });
            }
        }
    };

    /**
     * FormHandler.prototype.changeElementAmount
     * 
     * @param $ele
     * @param increase
     */
    FormHandler.prototype.changeElementAmount = function ($ele, increase) {
        var currentValue = parseInt($ele.val());

        if (isNaN(currentValue)) {
            currentValue = 0;
        }

        if (increase) {
            currentValue += 1;
        } else {
            currentValue -= 1;
        }

        var min = $ele.attr('min');
        var max = $ele.attr('max');

        if (currentValue < min) {
            currentValue = min;
        }

        if (currentValue > max) {
            currentValue = max;
        }

        $ele.val(currentValue);
        this.onChangeElementAmount($ele);
    };

    /**
     * FormHandler.prototype.onChangeElementAmount
     * 
     * @param $ele
     */
    FormHandler.prototype.onChangeElementAmount = function ($ele) {
        if (this.waitingForFormFinish) {
            $(this).trigger('show-messages', {
                messages: [{
                    type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                    message: 'Unfinish request running.'
                }]
            });

            return;
        }

        var $form = $ele.closest('form');
        var existingTimeout = $form.data('submit-timeout');

        if (existingTimeout) {
            window.clearTimeout(existingTimeout);
            $form.data('submit-timeout', null);
        }

        var timeout = window.setTimeout(function () {
            $form.data('submit-timeout', null);
            $form.submit();
        }, 1000);

        $form.data('submit-timeout', timeout);
        this.setAmountSettingButtonState($ele);
    };

    /**
     * FormHandler.prototype.setAmountSettingButtonState
     * 
     * @param $ele
     */
    FormHandler.prototype.setAmountSettingButtonState = function ($ele) {
        var id = $ele.attr('id');
        var min = parseInt($ele.attr('min'));
        var max = parseInt($ele.attr('max'));
        var val = parseInt($ele.val());
        var buttons = $('[data-amount-reference=' + id + ']');
        var $button = null;
        var disabled = false;

        for (var i = 0, len = buttons.length; i < len; i++) {
            $button = $(buttons[i]);
            disabled = false;

            if ($button.data('click-action') == 'increaseOfferAmount' && val >= max) {
                disabled = true;
            } else if ($button.data('click-action') == 'decreaseOfferAmount' && val <= min) {
                disabled = true;
            }

            if (disabled) {
                $button.addClass('disabled');
                $button.prop('disabled', true);
            } else {
                $button.removeClass('disabled');
                $button.prop('disabled', false);
            }
        }
    };

    /**
     * getActiveEvents
     * 
     * @param startId
     * @param endId
     * @param eventData
     * @returns {Array}
     */
    var getActiveEvents = function (startId, endId, eventData) {
        var start = false;
        var end = false;
        var ret = [];

        for (var i = 0, len = eventData.length; i < len; i++) {
            if (eventData[i][0] == startId) {
                start = true;
            }

            if (start === true && end === false && eventData[i][3] === 1) {
                ret.push(eventData[i][0]);
            }

            if (eventData[i][0] == endId) {
                end = true;
            }
        }

        return ret;
    };

    /**
     * FormHandler.prototype.onChangeHotelTime
     * 
     * @param $ele
     */
    FormHandler.prototype.onChangeHotelTime = function ($ele) {
        var priceId = '#' + $ele.data('price-reference');
        var $priceEle = $(priceId);
        var config = $priceEle.data('config-data');

        if ($priceEle.length == 0) {
            return;
        }

        if ($ele.attr('id').match(/start/)) {
            var $start = $ele;
            var otherId = $ele.attr('id').replace(/start/, 'end');
            var $end = $('#' + otherId);
        } else {
            var $end = $ele;
            var otherId = $ele.attr('id').replace(/end/, 'start');
            var $start = $('#' + otherId);
        }

        if ($start.length == 0 || $end.length == 0) {
            return;
        }

        var activeEvents = getActiveEvents(
            $start.val(),
            $end.val(),
            config.eventData
        );

        if (activeEvents.length === 0) {
            return;
        }

        var num = Math.round(config.packageBasePrice * activeEvents.length * 100) / 100;

        $priceEle.text(num.toFixed(2).replace(/\./, config.moneyFormater[0]));
    };

    /**
     * FormHandler.prototype.onChangePricegroup
     * 
     * @param $ele
     */
    FormHandler.prototype.onChangePricegroup = function ($ele) {
        var self = this;
        var pricegroup = $ele.val();
        var url = $ele.data('url-package-pricegroup');

        new Converia.Core.AjaxRequest(url, {
            pricegroup_id: pricegroup
        }, {})
            .then(function (res) {
                $(self).trigger('in-process', {
                    end: true
                });

                if (res.messages) {
                    $(self).trigger('show-messages', {
                        messages: res.messages
                    });
                }

                if (res.content) {
                    var content = $(res.content).filter('[data-converia-module]').get(0);

                    $('[data-converia-module]').replaceWith(content);
                    $(self).trigger('refresh-listener', {
                        element: content
                    });
                }
            })
            .else(function (res) {
                $(self).trigger('in-process', {
                    end: true
                });

                if (res.messages) {
                    $(self).trigger('show-messages', {
                        messages: res.messages
                    });
                }
            })
            .send();

        $(this).trigger('in-process');
    };

    /**
     * FormHandler.prototype.onToggleContainerVisibility
     * 
     * @param id
     * @param action
     */
    FormHandler.prototype.onToggleContainerVisibility = function (id, action) {
        if (action == 'show') {
            $('#' + id).show();
        } else {
            $('#' + id).hide();
        }
    };

    /**
     * FormHandler.prototype.onToggleNagVisibility
     * 
     * @param id
     * @param action
     */
    FormHandler.prototype.onToggleNagVisibility = function (id, action) {
        if (action == 'show') {
            $('#' + id).show();
        } else {
            $('#' + id).hide();
        }
    };

    /**
     * FormHandler.prototype.setPayment
     * 
     * @param $ele
     */
    FormHandler.prototype.setPayment = function ($ele) {
        $ele.closest('form').trigger('submit');
    };

    /**
     * FormHandler.prototype.finishBooking
     * 
     * @param $ele
     */
    FormHandler.prototype.finishBooking = function ($ele) {
        var refId = '[data-component-id=' + $ele.data('ref') + ']';
        var url = $ele.data('action-url');
        var $form = $(refId);
        var oldUrl = $form.data('ajax-form');

        $form.data('ajax-form', url);
        $form.trigger('submit');
        $form.data('ajax-form', oldUrl);
    };

    /**
     * FormHandler.prototype.assignTemplates
     * 
     * @param ele
     * @param ref
     */
    FormHandler.prototype.assignTemplates = function (ele, ref) {
        $(ele).find('[' + ref + ']').each(function () {
            $(this).val($(this).attr(ref));
        });
    };

    /**
     * FormHandler.prototype.hideContainer
     * 
     * @param id
     */
    FormHandler.prototype.hideContainer = function (id) {
        $('#' + id).hide();
    };

    /**
     * FormHandler.prototype.onIgnoreGroupAdminContact
     * 
     * @param url
     */
    FormHandler.prototype.onIgnoreGroupAdminContact = function (url) {
        new Converia.Core.AjaxRequest(url)
            .then(function () {
            }).else(function () {
        }).send();
    };

    /**
     * FormHandler.prototype.onTakeAdminContact
     */
    FormHandler.prototype.onTakeAdminContact = function () {
        var $hiddenInput = $('<input/>', {
            type: 'hidden',
            name: 'groupAdmin',
            val: '1'
        });

        $hiddenInput.appendTo('#participant-form');
    };

    /**
     * FormHandler.prototype.onSetPricegroup
     */
    FormHandler.prototype.onSetPricegroup = function () {
        var pricegroupVerificationInfo = $('[data-component-id="pricegroup-verification-info"]');
        var pricegroupMemberInfo = $('[data-component-id="pricegroup-member-info"]');

        pricegroupVerificationInfo.addClass('hide');
        pricegroupMemberInfo.addClass('hide');
        $('[data-component-id^="member_wrapper_"]').addClass('hide');

        var selectedOptions = $('[name=price_group]:checked');

        for (var i = 0, len = selectedOptions.length; i < len; i++) {
            if ($(selectedOptions[i]).data('pricegroup-verification') == '1') {
                pricegroupVerificationInfo.removeClass('hide');
            }

            if ($(selectedOptions[i]).data('pricegroup-verification') == '2') {
                var pgId = $(selectedOptions[i]).val();
                $('[data-component-id="member_wrapper_' + pgId + '"]').removeClass('hide');
                pricegroupMemberInfo.removeClass('hide');
            }
        }
    };

    return FormHandler;
});