/**
 * Converia.Core.module
 * 
 * Converia.InviteeManagement.App
 */

Converia.Core.module('Converia.InviteeManagement.App', function () {

    /**
     * App
     * @param basicElement
     * @param config
     * @constructor
     */
    var App = function (basicElement, config) {
        var self = this;

        this.config = config;
        this.waitingForRequest = false;
        this.processIcon = new Converia.Core.ProcessIcon();
        this.messageHandler = new Converia.Core.MessageHandler();
        this.messageHandler.pushMessages(config.messages || []);
        this.formHandler = new Converia.InviteeManagement.FormHandler();
        this.modalDialog = new Converia.Core.Modal();
        this.collapseHelper = new Converia.InviteeManagement.CollapseHandler();

        var proxy = $.proxy(this.triggerModalClose, this);
        $(this.formHandler).on('close-modal', proxy);

        var proxy = $.proxy(this.refreshListener, this);
        $(this.formHandler).on('refresh-listener', proxy);

        var proxy = $.proxy(this.triggerMessages, this);
        $(this.formHandler).on('show-messages', proxy);
        $(this).on('show-messages', proxy);

        var proxy = $.proxy(this.processListener, this);
        $(this.formHandler).on('in-process', proxy);
        $(this).on('in-process', proxy);

        var proxy = $.proxy(this.triggerModalOpen, this);
        $(this.formHandler).on('open-modal', proxy);

        var proxy = $.proxy(this.triggerRefreshView, this);
        $(this.formHandler).on('refresh-view', proxy);

        $(this.modalDialog).on('before-open', function (event, ele) {
            self.appendHandler(ele);
        });

        this.appendHandler(basicElement);
    };

    /**
     * App.prototype.processListener
     * 
     * @param event
     * @param data
     */
    App.prototype.processListener = function (event, data) {
        if (data && data.end) {
            this.processIcon.hide();
        } else {
            this.processIcon.show();
        }
    };

    /**
     * App.prototype.scrollToError
     * 
     * @param baseElement
     */
    App.prototype.scrollToError = function (baseElement) {
        if ($(baseElement).hasClass('error')) {
            var $base = $(baseElement);
        } else {
            var $base = $(baseElement).find('.error');
        }

        if ($base.length == 0) {
            return;
        }

        var scrollableErrorHandling = $base.closest('[data-scrollable-errors]');

        if (scrollableErrorHandling.length == 0) {
            return;
        }

        var ref = scrollableErrorHandling.data('scrollable-errors');
        var parentRef = $(ref);

        if (ref.match('body') && ref.match('html')) {
            var scrollTop = $base.offset().top - 30
        } else {
            var $ele = $base;
            var $lastEle = $ele;
            var found = false;
            var scrollTop = $ele.position().top;

            while (($ele = $ele.offsetParent()) && $ele.length) {
                if ($ele.get(0) == parentRef.get(0)) {
                    found = true;
                    break;
                }

                scrollTop += $ele.position().top;

                if ($lastEle.get(0) == $ele.get(0)) {
                    break;
                }

                $lastEle = $ele;
            }

            if (!found) {
                return;
            }

            scrollTop -= 30;
        }

        console.log('scroll');
        parentRef.animate({
            scrollTop: scrollTop
        }, 500);
    };

    /**
     * hasScrollBar
     * 
     * @param ele
     * @returns {boolean}
     */
    var hasScrollBar = function (ele) {
        return ele ? ele.scrollHeight > $(ele).innerHeight() : false;
    };

    /**
     * App.prototype.triggerRefreshView
     * 
     * @param event
     * @param data
     */
    App.prototype.triggerRefreshView = function (event, data) {
        if (this.waitingForRequest) {
            this.messageHandler.pushMessages([{
                type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                message: 'Unfinish request running.'
            }]);
            return;
        }

        var url = this.config.refreshUrl;

        if (url) {
            var success = $.proxy(this.onRefreshSuccess, this);
            var error = $.proxy(this.onRefreshError, this);
            new Converia.Core.AjaxRequest(
                url,
                {},
                {})
                .then(success)
                .else(error)
                .send();
            this.waitingForRequest = true;
            $(this).trigger('in-process');
        }
    };

    /**
     * App.prototype.onRefreshSuccess
     * 
     * @param data
     */
    App.prototype.onRefreshSuccess = function (data) {
        this.waitingForRequest = false;

        $(this).trigger('in-process', {end: true});

        if (data.messages) {
            this.messageHandler.pushMessages(data.messages);
        }

        if (data.content) {
            var content = $(data.content).filter('[data-converia-module]').get(0);

            $('[data-converia-module]').replaceWith(content);

            $(this).trigger('refresh-listener', {element: content});
        }
    };

    /**
     * App.prototype.onRefreshError
     * 
     * @param data
     */
    App.prototype.onRefreshError = function (data) {
        this.waitingForRequest = false;
        $(this).trigger('in-process', {
            end: true
        });

        if (data.connectionError) {
            this.messageHandler.pushMessages([{
                type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                message: 'Connection to server not possible.'
            }]);

            return;
        }

        if (data.messages) {
            this.messageHandler.pushMessages(data.messages);
        }
    };

    /**
     * App.prototype.triggerMessages
     * 
     * @param event
     * @param data
     */
    App.prototype.triggerMessages = function (event, data) {
        this.messageHandler.pushMessages(data.messages || []);
    };

    /**
     * App.prototype.triggerModalClose
     * 
     * @param event
     * @param data
     */
    App.prototype.triggerModalClose = function (event, data) {
        if (data.force) {
            this.modalDialog.forceClose();
        } else {
            this.modalDialog.close();
        }
    };

    /**
     * App.prototype.triggerModalOpen
     * 
     * @param event
     * @param data
     */
    App.prototype.triggerModalOpen = function (event, data) {
        var config = data.config || {};

        this.modalDialog.open(
            data.content,
            config
        );
    };

    /**
     * App.prototype.refreshListener
     * 
     * @param event
     * @param data
     */
    App.prototype.refreshListener = function (event, data) {
        if (data.element) {
            this.appendHandler(data.element);
        }
    };

    /**
     * App.prototype.appendHandler
     * 
     * @param basicElement
     */
    App.prototype.appendHandler = function (basicElement) {
        var self = this;

        $(basicElement).find('[data-modal]').on('click', function (event) {
            var config = $(this).data('modal-config') || {};

            self.modalDialog.open(
                this,
                config
            );
            event.preventDefault();
        });

        /**
         * companyList
         * 
         * @type {*|Array}
         */
        var companyList = this.config.companyList || [];

        $(basicElement).find('[data-autocomplete="company"]').each(
            function () {
                new Converia.Core.CompanyProposal(this, companyList);
            }
        );

        $(basicElement).find('[data-click-action]').on('click', function (event) {
            var $ele = $(this);
            switch ($ele.data('click-action')) {
                case 'collapse':
                    self.collapseHelper.toggle(this);
                    event.preventDefault();
                    break;
                case 'openCollapse':
                    var id = '#' + $ele.data('ref');
                    $(id).trigger('click');
                    break;
                case 'deleteItem':
                    var url = $ele.data('action-url');
                    self.cartHandler.removeItem(url);
                    event.preventDefault();
                    break;
                case 'submit':
                    var id = '[data-component-id=' + $ele.data('form-ref') + ']';
                    $(id).trigger('submit');
                    event.preventDefault();
                    break;
                case 'assignTemplates':
                    var ref = $ele.data('data-reference');
                    self.formHandler.assignTemplates(basicElement, ref);
                    event.preventDefault();
                    break;
                default:
                    break;
            }

            return true;
        });

        this.collapseHelper.keepOpen($(basicElement).find('[data-click-action=collapse]'));

        $(basicElement).find('[data-popover-content]').on(
            'click',
            function (event) {
                event.preventDefault();
                
                var options = $.extend({addClose: true}, $(this).data('popover-config'));
                var popover = new Converia.Core.Popover(
                    this,
                    options
                );
                
                popover.toggle();
                var tip = popover.getTip();
                
                if (tip) {
                    self.appendHandler(tip);
                }
            }
        );

        $(basicElement).find('[data-modal-autoopen]').trigger('click');
        this.scrollToError(basicElement);

        var onAjaxFormSubmitProxy = $.proxy(this.formHandler.onAjaxFormSubmit, this.formHandler);
        $(basicElement).find('[data-ajax-form]').on('submit', onAjaxFormSubmitProxy);
        $(basicElement).filter('[data-ajax-form]').on('submit', onAjaxFormSubmitProxy);
    };

    return App;
});