/**
 * Converia Registration DialogHandler
 */

Converia.Core.module('Converia.Registration.DialogHandler', function () {
    /**
     * DialogHandler
     * 
     * @constructor
     */
    var DialogHandler = function () {
        this.currentUrl;
    };

    /**
     * DialogHandler.prototype.dispatchDialogDecision
     * 
     * @param actionKey
     * @param dialog
     */
    DialogHandler.prototype.dispatchDialogDecision = function (actionKey, dialog) {
        var method = actionKey;

        if (this[method]) {
            this[method](dialog);
        }
    };

    /**
     * DialogHandler.prototype.forceVoucherNo
     * 
     * @param dialog
     */
    DialogHandler.prototype.forceVoucherNo = function (dialog) {
        dialog.forceClose();

        if (popover = $('[data-component-id=voucher-redeemer-trigger]').data('converia-popover')) {
            popover.hide();
        }
    };

    /**
     * DialogHandler.prototype.forceVoucherYes
     * 
     * @param dialog
     */
    DialogHandler.prototype.forceVoucherYes = function (dialog) {
        dialog.forceClose();

        if (form = $('[data-component-id=voucher-redeemer]')) {
            form.find('[name=force]').val(2);
            form.submit();
        }
    };

    /**
     * DialogHandler.prototype.forceVoucherYesReset
     * 
     * @param dialog
     */
    DialogHandler.prototype.forceVoucherYesReset = function (dialog) {
        dialog.forceClose();

        if (form = $('[data-component-id=voucher-redeemer]')) {
            form.find('[name=force]').val(1);
            form.submit();
        }
    };

    /**
     * DialogHandler.prototype.deleteParticipantNo
     * 
     * @param dialog
     */
    DialogHandler.prototype.deleteParticipantNo = function (dialog) {
        dialog.forceClose();
    };

    /**
     * DialogHandler.prototype.deleteParticipantYes
     * 
     * @param dialog
     */
    DialogHandler.prototype.deleteParticipantYes = function (dialog) {
        dialog.forceClose();

        if (this.currentUrl) {
            document.location = this.currentUrl;
        }
    };

    /**
     * DialogHandler.prototype.setCurrentUrl
     * 
     * @param currentUrl
     */
    DialogHandler.prototype.setCurrentUrl = function (currentUrl) {
        this.currentUrl = currentUrl;
    };

    return DialogHandler;
});