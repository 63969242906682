Converia.Core.module('Converia.Core.MessageHandler', function () {


    var MessageHandler = function (messageArray) {

    };

    MessageHandler.MSG_TYPES = {
        MSG_TYPE_SUCCESS: 1,
        MSG_TYPE_ERROR: 2,
        MSG_TYPE_INFO: 3,
        MSG_TYPE_WARNING: 4,
        MSG_TYPE_INTERACTION_ERROR: 20
    };

    MessageHandler.prototype.pushMessages = function (messageArray) {
        for (var i = 0, len = messageArray.length; i < len; i++) {
            this.pushMessage(messageArray[i].message, messageArray[i].type)
        }
    };

    MessageHandler.prototype.pushMessage = function (msg, type) {
        var that = this;

        if (typeof(type) === 'undefined') {
            type = MessageHandler.MSG_TYPES.MSG_TYPE_INFO;
        }

        // Toastr default overrides
        toastr.options.timeOut = 15000;
        toastr.options.extendedTimeOut = 15000;
        toastr.options.messageClass = 'toast-message';
        toastr.options.closeButton = true;
        toastr.options.progressBar = true;
        toastr.options.tapToDismiss = false;
        toastr.options.iconClasses = {
            error: 'fe-icon-error',
            info: 'fe-icon-info',
            success: 'fe-icon-success',
            warning: 'fe-icon-warning'
        };

        toastr.options.iconClass = 'fe-icon-info';
        toastr.options.closeHtml = '<span class="fe-icon fe-icon-close"></span>';

        switch (type) {
            case MessageHandler.MSG_TYPES.MSG_TYPE_SUCCESS:
                toastr.success(msg);
                break;
            case MessageHandler.MSG_TYPES.MSG_TYPE_ERROR:
                toastr.error(msg);
                break;
            case MessageHandler.MSG_TYPES.MSG_TYPE_INTERACTION_ERROR:
                toastr.options.timeOut = 0;
                toastr.options.extendedTimeOut = 0;

                var element = toastr.error(msg);

                $(element).on('click', '[data-message-interaction]', function(event) {
                    var elementConfig = $(this).data('message-interaction');
                    var interactionEventName = elementConfig[0];
                    var interactionEventConfig = elementConfig[1];

                    $(that).trigger(interactionEventName, interactionEventConfig);
                    event.preventDefault();
                });

                break;
            case MessageHandler.MSG_TYPES.MSG_TYPE_WARNING:
                toastr.warning(msg);
                break;
            case MessageHandler.MSG_TYPES.MSG_TYPE_INFO:
            default:
                toastr.info(msg);
        }
    };

    return MessageHandler;
});
