/**
 * Converia Registration Payment App
 */
Converia.Core.module('Converia.Registration.Payment.App', function () {
    var App = function (basicElement, config) {
        $("[data-modal]").click(function (event) {
            event.preventDefault();
            var modal = new Converia.Core.Modal();
            modal.open(this, {});
        });
        $(basicElement).find('input[name="payment"]').on('click', function (event) {
                if ($(this).data('payment-testmode') == '1' && $(this).is(':checked')) {
                    $('#payment-testmode-alert').removeClass('hide')
                } else {
                    $('#payment-testmode-alert').addClass('hide')
                }
            }
        );
    };
    
    return App;
});
