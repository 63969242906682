/**
 * Document ready
 */

var processIcon = null;

/**
 * printContent
 * 
 * @param id
 */
function printContent(id) {
    var container = document.getElementById(id).cloneNode(true);
    $(container).find('.content_wrapper').css('height', 'auto');
    $(container).printThis();
    return;
}

(function ($) {
    var opt;

    /**
     * printThis
     * 
     * @param options
     */
    $.fn.printThis = function (options) {
        opt = $.extend({}, $.fn.printThis.defaults, options);

        var $element = this instanceof jQuery ? this : $(this);
        var strFrameName = "printThis-" + (new Date()).getTime();

        if (window.location.hostname !== document.domain && navigator.userAgent.match(/msie/i)) {
            // Ugly IE hacks due to IE not inheriting document.domain from parent
            // checks if document.domain is set by comparing the host name against document.domain
            var iframeSrc = "javascript:document.write(\"<head><script>document.domain=\\\"" + document.domain + "\\\";</script></head><body></body>\")";
            var printI = document.createElement('iframe');

            printI.name = "printIframe";
            printI.id = strFrameName;
            printI.className = "MSIE";
            document.body.appendChild(printI);
            printI.src = iframeSrc;
        } else {
            // other browsers inherit document.domain, and IE works if document.domain is not explicitly set
            var $frame = $("<iframe id='" + strFrameName + "' name='printIframe' />");
            $frame.appendTo("body");
        }

        var $iframe = $("#" + strFrameName);

        // show frame if in debug mode
        if (!opt.debug) $iframe.css({
            position: "absolute",
            width: "0",
            height: "0",
            left: "-600px",
            top: "-600px"
        });

        // $iframe.ready() and $iframe.load were inconsistent between browsers  
        setTimeout(function () {

            var $doc = $iframe.contents();

            // import page stylesheets
            if (opt.importCSS) $('link[rel=stylesheet]').each(function () {
                var href = $(this).attr('href');
                if (href) {
                    //var media = $(this).attr("media") || "all";
                    $doc.find('head').append('<link rel="stylesheet" href="' + href + '">')
                }
            });

            // add title of the page
            if (opt.pageTitle) {
                $doc.find('head').append('<title>' + opt.pageTitle + '</title>');
            }

            // import additional stylesheet
            if (opt.loadCSS) {
                $doc.find('head').append('<link rel="stylesheet" href="' + opt.loadCSS + '">');
            }

            // grab $.selector as container
            if (opt.printContainer) {
                $doc.find('body').append($element.outer());
            } else $element.each(function () {
                // otherwise just print interior elements of container
                $doc.find('body').append($(this).html())
            });

            // remove inline styles
            if (opt.removeInline) {
                // $.removeAttr available jQuery 1.7+
                if ($.isFunction($.removeAttr)) {
                    $doc.find("body *").removeAttr("style");
                } else {
                    $doc.find("body *").attr("style", "");
                }
            }

            setTimeout(function () {
                if ($iframe.hasClass("MSIE")) {
                    // check if the iframe was created with the ugly hack
                    // and perform another ugly hack out of neccessity
                    window.frames["printIframe"].focus();
                    $doc.find("head").append("<script>  window.print(); </script>");
                } else {
                    // proper method
                    $iframe[0].contentWindow.focus();
                    $iframe[0].contentWindow.print();
                }

                //remove iframe after print
                if (!opt.debug) {
                    setTimeout(function () {
                        $iframe.remove();
                    }, 1000);
                }
            }, 333);
        }, 333);
    };

    /**
     * printThis
     * defaults
     * 
     * @type {{debug: boolean, importCSS: boolean, printContainer: boolean, loadCSS: string, pageTitle: string, removeInline: boolean}}
     */
    $.fn.printThis.defaults = {
        debug: false,           // show the iframe for debugging
        importCSS: true,        // import parent page css
        printContainer: true,   // print outer container/$.selector
        loadCSS: '',            // load an additional css file
        pageTitle: '',          // add title to print page
        removeInline: false     // remove all inline styles
    };

    /**
     * outer
     * $.selector container
     * 
     * @returns {*|jQuery}
     */
    jQuery.fn.outer = function () {
        return $($('<div></div>').html(this.clone())).html()
    };
})(jQuery);

$(document).ready(function () {
    processIcon = new Converia.Core.ProcessIcon();
    new Converia.Registration.App('.hint', {messages : ""});

    ConveriaFE.initCompanyHelper();
    var $element = $('[data-utf8-keyboard]');
    var config = $element.data('utf8-keyboard-config') || {};
    $element.UTF8Keyboard(config);

    $('[data-counter-append]').each(
        function () {
            new Converia.Core.WordCharCounter(this, $(this).data('counter-append'));
        }
    );


    /**
     * matchHeight
     *
     * compare heights of given elements and equalize their heights
     * 
     * @TODO: Replace this hacky way with flexbox where possible.
     */

    $('.module-footer .module-card').matchHeight();
    // $('.address-equal-heights').matchHeight();
    // $('.registration-finished-intro-column').matchHeight();
    $('.frontend-module-full-width .form-login-group-admin .module-card').matchHeight();

    var doubleSubmission = [];

    $('[data-prevent-double-submit]').on('submit', function () {
        for (var i = 0, len = doubleSubmission.length; i < len; i++) {
            if (this === doubleSubmission[i]) {
                return false;
            }
        }

        doubleSubmission.push(this);

        return true;
    });
});

ConveriaFE = {
    translations: [],
    companyList: []
};


/**
 * Init Company Helper
 */

ConveriaFE.initCompanyHelper = function () {
    $('[data-company-proposal]').each(
        function (i, ele) {
            $(ele).attr("autocomplete", "off");
            $(ele).typeahead({
                source: ConveriaFE.companyList,
                minLength: 3,
                items: 9999
            });

            ConveriaFE.chromeAutofillWorkaround(ele);
        }
    );
};


/**
 * Chrome Autofill Workaround
 */

ConveriaFE.chromeAutofillWorkaround = function (ele) {
    var form = $(ele).closest('form');
    var chromeWorkaround = '<input type="text" id="PreventChromeAutocomplete" name="PreventChromeAutocomplete" autocomplete="address-level4" style="display: none;">';

    form.prepend(chromeWorkaround);
};


/**
 * Process Bar
 */

ConveriaFE.ProcessBar = function () {
    var percentage = 0;
    var overlay = '<div class="fancybox-overlay fancybox-overlay-fixed" style="width: auto; height: auto; display: block;"><div class="begui-progress-bar"><div class="progress-bar"><div class="bar" style="width: 0%;"></div></div></div></div>';
    var ele = $(overlay);

    this.setState = function (perc) {
        percentage = perc;
        ele.find('.bar').css('width', percentage + '%');
    };

    this.show = function () {
        $('body').append(ele);
    };

    this.hide = function () {
        $('.begui-progress-bar').parent().remove();
    }
};


/**
 * Mass Action Helper
 */

ConveriaFE.MassActionHelper = function (url, runName, parameter) {
    var $this = this;
    var state = 'start';
    var processBar = new ConveriaFE.ProcessBar();

    var successHandler = function (result) {
        state = 'running';

        if (result.state == 'error') {
            processBar.hide();
            $($this).trigger("error", {msg: result.msg});
        } else if (result.state == 'running') {
            if (result.percentageNumber) {
                processBar.setState(result.percentageNumber);
            }

            $($this).trigger("running", {msg: result.msg});
            $this.run();
        } else if (result.state == 'ready') {
            processBar.hide();
            $($this).trigger("ready", {msg: result.msg});
        }
    };

    this.run = function () {
        processBar.show();
        $.ajax({
            url: url,
            type: "POST",
            data: {
                runName: runName,
                runData: parameter,
                state: state
            },
            cache: false,
            success: successHandler,
            error: function (result) {
                processBar.hide();
                $($this).trigger("error", {msg: result.msg});
            },
            dataType: "json"
        });
    }
};

// Calculate height of subnav aside
//-------------------------------------

var scrollCalcAppended = false;

function asideCalc() {
    var pageFooter = $('.page-footer');

    if (pageFooter.length < 1) {
        return false;
    }

    if ($('html').hasClass('demo')) {
        var $affixTop = '68';
    } else {
        var $affixTop = '28';
    }

    viewportHeight = $(window).height();
    contentHeight = $('.content').outerHeight();
    asideHeight = $('.aside-registration').find('.affix').outerHeight();
    asideFull = parseInt(asideHeight) + parseInt($affixTop) + 2;
    footerLimit = pageFooter.offset().top - parseInt(asideHeight) - 50;

    // logging
    //console.log('viewportHeight: ' + viewportHeight);
    //console.log('contentHeight: ' + contentHeight);
    //console.log('asideHeight: ' + asideHeight);
    //console.log('asideFull: ' + asideFull);
    //console.log('footerLimit: ' + footerLimit);


    // only fix nav when viewport is higher than aside
    // and aside higher than content column
    //------------------------------------------------

    if (!scrollCalcAppended && viewportHeight > asideFull) {
        scrollCalcAppended = true;

        $('.aside-registration .affix').scrollToFixed({
            marginTop: parseInt($affixTop),
            limit: function () {
                return footerLimit
            },
            unfixed: function () {
                $(this).css({
                        left: "0"
                    }
                )
            }
        });
    }
}


/**
 * Download Process
 */

ConveriaFE.DownloadProcess = function () {
    this.onClick = function (event, ele) {
        event.preventDefault();

        var date = new Date();
        var key = date.getTime();
        var url = $(ele).attr('href') + '&downloadToken=' + key;
        var processIcon = new Converia.Core.ProcessIcon();

        var cb = function () {
            var token = getCookie("downloadToken");

            if (token == key) {
                processIcon.hide();
                window.clearInterval(interval);
            } else {
            }
        };

        var interval = window.setInterval(cb, 1000);

        processIcon.show();
        document.location.href = url;
    };

    var getCookie = function (name) {
        var parts = document.cookie.split(name + "=");

        if (parts.length == 2) {
            return parts.pop().split(";").shift();
        }
    }
};


/**
 * Add Translation
 */

ConveriaFE.addTranslation = function (key, value) {
    this.translations[key] = value;
    return this;
};


/**
 * Translate
 */

ConveriaFE.translate = function (key) {
    if (typeof(this.translations[key]) == 'undefined') {
        return key;
    }

    return this.translations[key];
};

// Window load stuff
//-------------------------------------

$(window).load(function () {

    // Fixed aside
    // only fired, when viewport higher than aside
    // needs to wait for full window to load (images)
    //-----------------------------------------------

    asideCalc();

    var helper = new ConveriaFE.DownloadProcess();

    $('[data-download-inprogress]').click(
        function (event) {
            helper.onClick(event, this);
        }
    );

    $('.user-html-content').each(
        function () {
            var ele = this;
            var iframeContent = $(ele).data('iframe-content');

            $(ele).html('<iframe src="/frontend/lib/templates/iframe-content.html" frameborder="0"></iframe>');

            var iframe = $(ele).find('iframe');

            iframe.load(function () {
                iframe
                    .contents()
                    .find('body')
                    .html('<div class="content">' + iframeContent + '</div>');

                iframe
                    .width($(ele)
                        .width());

                iframe
                    .height(
                        iframe
                            .contents()
                            .find('body')
                            .css('margin', 0)
                            .outerHeight() + 45
                    );
            });
        }
    );
});



/**
 * Document ready
 * 
 * Validate, if this stuff can be placed in $(document).ready()
 */
$(document).on('ready', function () {
    
    
    $("#payment-redirect-form").submit();

    $('[data-converia-module]').each(
        function () {
            var moduleName = $(this).data('converia-module');
            var config = $(this).data('converia-module-config') || {};
            var module = Converia.Core.initApp(moduleName, this, config);
        }
    );

    // Modal for lightbox images
    // trigger it manually to have full control
    $('[data-image-overlay]').on('click', function (event) {
        event.preventDefault();

        var image = $(this).data('image-overlay');
        //var title = $(this).data('image-title');
        var modal = new Converia.Core.StructuredModal();

        modal.open(
            '',
            image,
            '',
            {
                addClass: "overlay-modal-lightbox"
            }
        );
    });
});


/**
 * Document 
 */
$(document).ready(function () {
    /**
     * Insert alt content for hover element
     */
    
    // Not using Modernizr anymore so this is not working. Evaluate different solution.
    //if ($('.structured-list-item-inner-wrapper > a').not('.hold') && $('html').hasClass('no-touchevents')) {
    if ($('.structured-list-item-inner-wrapper > a').not('.hold')) {
        $('.structured-list-item-inner-wrapper > a').hover(
            function () {
                var altContent = $(this).find('img').attr('alt');
                var hoverItemHeight = $(this).find('.structured-list-item-image, .structured-list-item-dummy-image').outerHeight() + 1;

                if ($(this).closest('.structured-list-wrapper-speakers').length > 0) {
                    // set height of hover overlay
                    $(this).append('<div class="structured-list-item-hover" style="height: ' + hoverItemHeight + 'px;"><span class="structured-list-item-hover-content"><span class="button">Details</span></span></div>');
                }

                if ($(this).closest('.structured-list-wrapper-sponsor').length > 0) {
                    $(this).append('<div class="structured-list-item-hover"><span class="structured-list-item-hover-content">' + altContent + '</span></div>');
                }
            },

            function () {
                $(this)
                    .find('.structured-list-item-hover')
                    .remove();
            }
        );
    }


    /**
     * Structured data accordion
     */

    // preserve original margin of list items
    $structuredListItemsArray = $('.structured-list-wrapper li');

    function setStructuredListOffsets() {
        var cachedOffsetTop = 0;
        var rowIndex = 0;
        var $lastUl = null;
        var $previousLi;

        $structuredListItemsArray.each($.proxy(function (i, elm) {
            var $li = $(elm);
            var liData = $li.data();

            $li.removeClass('structured-list-item-row-' + liData.rowIndex);

            // update the classes only of the visible lis
            var tmpOffset = $li.position();
            var liPosition = {
                top: ~~tmpOffset.top,
                left: ~~tmpOffset.left
            };

            // increase by one row if the element has an offset different from the previous one
            // considering images having the same offset only the ones having an offset gap smaller than 6px
            // (to fix a webkit weird behavior)
            if (liPosition.top >= cachedOffsetTop + 3 || liPosition.top <= cachedOffsetTop - 3 || ($lastUl !== null && $lastUl != $(elm).parent().get(0))) {
                rowIndex++;
            }

            // add the needed classes to detect the li inside the grid
            $li.addClass('structured-list-item-row-' + rowIndex);

            // store the wall data
            $li.data({
                rowIndex: rowIndex,
                offset: liPosition,
                index: i
            });

            $previousLi = $li;
            cachedOffsetTop = liPosition.top;
            $lastUl = $(elm).parent().get(0);
        }));
    }

    $('.structured-list-item-inner-wrapper > a').on('click', function (e) {
        e.preventDefault();

        var $this = $(this);
        var $thisParent = $this.parent().parent();
        var $thisNextContent = $this.next('.structured-list-item-content');
        var $thisImage = $this.children('img');
        var $thisImageHeight = $thisImage.outerHeight();
        var $thisItemHeight = $this.outerHeight();
        var $anyDescription = $('.structured-list-item-content');
        var $anySponsorItem = $('.structured-list-item');
        let timer;
        // reset any margins
        $anySponsorItem.css({
            'margin-bottom': 0
        });

        // remove any existing js elements
        $('.structured-list-item-close').remove();
        $('.structured-list-item-hold').remove();
        $('.structured-list-item-image-wrap').remove();
        
        // run condition
        if ($thisNextContent.hasClass('open')) {
            // kill any assisting classes
            $this.removeClass('hold');
            $anyDescription.removeClass('open');
            $this.get(0).scrollIntoView({behavior: "smooth", block: 'start', inline: 'start'});
        } else {
            // remove any images
            $thisNextContent.children('img').remove();

            // clone image
            let imageHeight = parseInt($thisImage.attr('height'));
            
            function calculateSetListItemHeight ($imageElement) {
                $imageElement.off('load');
                var altContent = $this.find('img').attr('alt');
    
                // kill any assisting classes
                $this.removeClass('hold');
                $anyDescription.removeClass('open');
    
                var rowIndex = $thisParent.data('rowIndex');
                var currentIndex = 0;
    
                // add close button
                $thisNextContent.prepend('<span class="structured-list-item-close"><span class="fe-icon fe-icon-remove"></span></span>');
    
                // hold company name
                $this.addClass('hold');

                $imageElement.append('<div class="structured-list-item-hold"><span class="structured-list-item-hover-content">' + altContent + '</span></div>');
    
                // show desc
                $thisNextContent.addClass('open');
    
                let outerHeight = parseInt($thisNextContent.outerHeight(true));
                var descHeight = outerHeight;
    
                for (var i = 0, len = $anySponsorItem.length; i < len; i++) {
                    currentIndex = $($anySponsorItem[i]).data('rowIndex');
    
                    if (currentIndex == rowIndex) {
                        $($anySponsorItem[i]).css('margin-bottom', descHeight);
                    }
                }
    
                if ( timer ) clearTimeout(timer);
    
                timer = setTimeout(() => {
                    $thisNextContent.get(0).scrollIntoView({behavior: "smooth", block: 'start', inline: 'nearest'});
                }, 100);
            }
            
            if ($thisImage.length > 0) {
                $thisImage
                    .clone().on('load', () => {calculateSetListItemHeight($(this))})
                    .prependTo(
                        $this
                            .closest('.structured-list-item')
                            .find('.structured-list-item-content')
                    )
                    .css({
                        'max-height': $thisItemHeight + 'px'
                    })
                    .wrap('<div class="structured-list-item-image-wrap" style="max-height: ' + $thisItemHeight + 'px"></div>');
            } else {
                calculateSetListItemHeight($([]))
            }
        }
    });


    /**
     * Closing behavior
     * fake a click with all functions above
     */

    $(document).on('click', '.structured-list-item-close', function () {
        $(this)
            .closest('.structured-list-item')
            .find('a')
            .trigger('click');


        // if speaker and it is opened (y do i need)
        $('.speaker-hover').remove();
    });


    /**
     * Mobile navigation
     */

    // Toggle buttons
    var buttonNavMain = $('[data-mobile="nav-button-main"]');
    var buttonNavC3 = $('[data-mobile="nav-button-c3"]');
    var buttonNavLang = $('[data-mobile="nav-button-lang"]');
    var buttonNavMeta = $('[data-mobile="nav-button-meta"]');

    // Nav wrappers
    var navWrapper = $('.nav-wrapper');
    var mobileNavWrapperMain = $('[data-mobile="nav-wrapper-main"]');
    var mobileNavWrapperC3 = $('[data-mobile="nav-wrapper-c3"]');
    var mobileNavWrapperLang = $('[data-mobile="nav-wrapper-lang"]');
    var mobileNavWrapperMeta = $('[data-mobile="nav-wrapper-meta"]');

    // hide some stuff when empty
    if (mobileNavWrapperC3.length === 0) {
        buttonNavC3.hide();
    }

    if (!mobileNavWrapperMain.is(':visible')) {
        buttonNavMain.hide();
    }

    // Main navigation
    buttonNavMain.on('click', function () {
        // Close main nav when alread open
        if (buttonNavMain.hasClass('pressed')) {
            buttonNavMain.removeClass('pressed');
            navWrapper.removeClass('nav-open');
            mobileNavWrapperMain.removeClass('open')
        } else {
            // Open main nav when closed
            buttonNavMain.addClass('pressed');
            mobileNavWrapperMain.addClass('open');
            navWrapper.addClass('nav-open');
        }
        
        // Close other navs when button for main nav is clicked
        if (buttonNavC3.hasClass('pressed')) {
            buttonNavC3.removeClass('pressed');
            mobileNavWrapperC3.removeClass('open');
        }

        if (buttonNavLang.hasClass('pressed')) {
            buttonNavLang.removeClass('pressed');
            mobileNavWrapperLang.removeClass('open');
        }

        if (buttonNavMeta.hasClass('pressed')) {
            buttonNavMeta.removeClass('pressed');
            mobileNavWrapperMeta.removeClass('open');
        }
    });

    // C3 nav
    buttonNavC3.on('click', function () {
        // Close C3 nav when alread open
        if (buttonNavC3.hasClass('pressed')) {
            buttonNavC3.removeClass('pressed');
            mobileNavWrapperC3.removeClass('open')
        } else {
            // Open C3 nav when closed
            buttonNavC3.addClass('pressed');
            mobileNavWrapperC3.addClass('open');
        }

        // Close other navs when button for main nav is clicked
        if (buttonNavMain.hasClass('pressed')) {
            buttonNavMain.removeClass('pressed');
            mobileNavWrapperMain.removeClass('open');
        }

        if (buttonNavLang.hasClass('pressed')) {
            buttonNavLang.removeClass('pressed');
            mobileNavWrapperLang.removeClass('open');
        }

        if (buttonNavMeta.hasClass('pressed')) {
            buttonNavMeta.removeClass('pressed');
            mobileNavWrapperMeta.removeClass('open');
        }
    });

    // Lang nav
    buttonNavLang.on('click', function () {
        // Close Meta nav when alread open
        if (buttonNavLang.hasClass('pressed')) {
            buttonNavLang.removeClass('pressed');
            mobileNavWrapperLang.removeClass('open')
        } else {
            // Open Meta nav when closed
            buttonNavLang.addClass('pressed');
            mobileNavWrapperLang.addClass('open');
        }

        // Close other navs when button for main nav is clicked
        if (buttonNavMain.hasClass('pressed')) {
            buttonNavMain.removeClass('pressed');
            mobileNavWrapperMain.removeClass('open');
        }

        if (buttonNavC3.hasClass('pressed')) {
            buttonNavC3.removeClass('pressed');
            mobileNavWrapperC3.removeClass('open');
        }

        if (buttonNavMeta.hasClass('pressed')) {
            buttonNavMeta.removeClass('pressed');
            mobileNavWrapperMeta.removeClass('open');
        }
    });

    // Nav meta
    buttonNavMeta.on('click', function () {
        // Close Meta nav when alread open
        if (buttonNavMeta.hasClass('pressed')) {
            buttonNavMeta.removeClass('pressed');
            mobileNavWrapperMeta.removeClass('open')
        } else {
            // Open Meta nav when closed
            buttonNavMeta.addClass('pressed');
            mobileNavWrapperMeta.addClass('open');
        }

        // Close other navs when button for main nav is clicked
        if (buttonNavMain.hasClass('pressed')) {
            buttonNavMain.removeClass('pressed');
            mobileNavWrapperMain.removeClass('open');
        }

        if (buttonNavC3.hasClass('pressed')) {
            buttonNavC3.removeClass('pressed');
            mobileNavWrapperC3.removeClass('open');
        }

        if (buttonNavLang.hasClass('pressed')) {
            buttonNavLang.removeClass('pressed');
            mobileNavWrapperLang.removeClass('open');
        }
    });
    
    
    // Nav main level 2 opening on mobile
    $('[data-mobile="nav-submenu-toggle"]').on('click', function() {
        var $this = $(this);
        var parentListItem = $this.parent('.has-child');
        var submenu = $this.next('.level-2');

        if ($this.hasClass('pressed')) {
            $this.removeClass('pressed');
            submenu.removeClass('open');
            parentListItem.removeClass('child-open');
        } else {
            $this.addClass('pressed');
            submenu.addClass('open');
            parentListItem.addClass('child-open');
        }
    });



    /**
     * Scroll CTA
     */

    var scrollCta = $('a.go-on-scroll-indicator');
    var scrollCtaTarget = $('#go-on-scroll-target');

    scrollCta.on('click', function (event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $(scrollCtaTarget).offset().top
        }, 1500);
    });



    /**
     * Run offset functions
     */

    $(window).resize(function () {
        setStructuredListOffsets();
    });

    $(window).load(function () {
        setStructuredListOffsets();
    });
});

$(window).load(function () {
    /**
     * Set equal height for structured items
     */

    $('.structured-list-wrapper .structured-list-item-inner-wrapper > a').matchHeight({
        byRow: false
    });
});

function initPaperSubmissionJs(delPaperString, delAuthorString, delAuthorAltString) {
    $('.add_confirm_dialog').click(function (event) {
        var text = 'Sind sie sicher?';
        var ele = $(this);
        var classes = ele.attr('class').split(/\s+/);

        if (ele.hasClass('del_author_btn')) {
            var pName = ele.parent().parent().find('.js_get_surname').val();

            if (pName) {
                var text = delAuthorString.replace(/##js_replace##/, pName);
            } else {
                var text = delAuthorAltString;
            }
        } else {
            var pName = ele.parent().find('.js_get_paper_name').text();
            var text = delPaperString.replace(/##js_replace##/, pName);
        }

        if (confirm(text)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    });
}
