/**
 * Converia Modal: iFrame
 */
Converia.Core.module('Converia.Core.StructuredModal', function() {
    /**
     * StructuredModal
     *
     * @param modalStack
     * @constructor
     */
    var StructuredModal = function (modalStack) {
        Converia.Core.Modal.call(
            this,
            modalStack
        );
    };

    /**
     * StructuredModal
     *
     * @type {Converia.Core.Modal}
     */
    StructuredModal.prototype = Object.create(
        Converia.Core.Modal.prototype,
        {}
    );
    
    /**
     * generateModalContentString
     * 
     * @param title
     * @param content
     * @param footer
     * @returns {string}
     */
    var generateModalContentString = function (title, content, footer) {
        var tplHeader = '<div class="overlay-header" role="heading">modal.header</div>';
        var tplContent = '<div class="overlay-content">modal.content</div>';
        var tplFooter = '<div class="overlay-footer">modal.footer</div>';
        var contentHtmlString = '';

        if (title) {
            contentHtmlString += tplHeader.replace(/modal\.header/, title);
        } else {
            //workaround as long not all modals have a header
            contentHtmlString += tplHeader.replace(/modal\.header/, '&nbsp;'); 
        }

        if (content) {
            contentHtmlString += tplContent.replace(/modal\.content/, content);
        }

        if (footer) {
            contentHtmlString += tplFooter.replace(/modal\.footer/, footer);
        }

        return contentHtmlString;
    };

    /**
     * StructuredModal
     * 
     * @param title
     * @param content
     * @param footer
     * @param options
     */
    StructuredModal.prototype.open = function(title, content, footer, options) {
        var innerContent = generateModalContentString(
            title,
            content,
            footer
        );

        Converia.Core.Modal.prototype.open.call(
            this,
            innerContent,
            options
        );
    };
    
    return StructuredModal;
});