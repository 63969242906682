Converia.Core.module('Converia.Core.Popover', function () {
    var Popover = function (ele, config) {
        this.ele = ele;
        this.options = config;
    };

    Popover.prototype.toggle = function () {
        var exist = $(this.ele).data('converia-popover');

        if (exist) {
            this.hide();
        } else {
            this.show();
        }
    };

    Popover.prototype.show = function () {
        var self = this;
        var content = '';

        if (this.options.addClose) {
            content = $('<span class="popover-close"><span class="fe-icon fe-icon-close"></span></span>').get(0);
 
            $(content).on('click', function () {
                self.hide();
            });
        }

        $(this.ele).popover({
            html: true,
            content: [content, $(this.ele).data('popover-content')],
            placement: 'bottom',
            trigger: 'manual',
            template: '' +
                '<div class="popover">' +
                '   <div class="arrow"></div>' +
                '   <div class="popover-title-wrapper">' +
                '       <span class="popover-title"></span>' +
                '   </div>' +
                '   <div class="popover-content"></div>' +
                '</div>'
        });

        $(this.ele).popover('show');
        $(this.ele).data('converia-popover', this);
    };

    Popover.prototype.hide = function () {
        if (!this.ele) {
            return;
        }

        $(this.ele).popover('hide');
        $(this.ele).popover('destroy');
        $(this.ele).data('converia-popover', null);

        this.ele = null;
    };

    Popover.prototype.getTip = function () {
        var popover = $(this.ele).data('popover');

        if (popover) {
            return popover.$tip.get(0);
        }

        return null;
    };

    return Popover;
});