Converia.Core.module('Converia.Core.ModalStack', function () {
	var ModalStack = function () {
		this.modalStack = [];
	};

	var appendListener = function(modal,stack){
		var proxy = $.proxy(stack.onClose, stack, modal);
		$(modal).on('closed.ModalStack',proxy);
		var proxy = $.proxy(stack.onShow, stack, modal);
		$(modal).on('before-shown.ModalStack',proxy);
		$(modal).off('.ModalStackEventForward');
		var eventForwards = [
			'before-open.ModalStackEventForward',
			'shown.ModalStackEventForward',
			'hidden.ModalStackEventForward',
			'closed.ModalStackEventForward',
            'before-closed.ModalStackEventForward',
		].join(' ');
		$(modal).on(eventForwards,function(event){
			$(stack).trigger(event.type,modal);
		});
        var modalShowChanges = [
            'shown.ModalStackEventForward',
            'hidden.ModalStackEventForward',
            'closed.ModalStackEventForward',
        ].join(' ');
        $(modal).on(modalShowChanges,function(event){
			//shown|hidden|closed have to be listened to determine current open state
			for(var i= 0, len = stack.modalStack.length;i<len;i++){
				if(stack.modalStack[i].isShown()){
					$(stack).trigger('is-open',stack.modalStack[i]);
					return;
				}
			}
			$(stack).trigger('is-closed');
        });
	}

	var dropListener = function(modal,all){
		$(modal).off('.ModalStack');
		if(all){
			$(modal).off('.ModalStackEventForward');
		}
	}

	var callHide = function(modal,stack){//call hide and prevent listener recursion
		dropListener(modal);
		modal.hide();
		appendListener(modal,stack)
	}

	var callShow = function(modal,stack){//call hide and prevent listener recursion
		dropListener(modal);
		modal.show();
		appendListener(modal,stack)
	}

	var callClose = function(modal){//call hide and prevent listener recursion
		dropListener(modal);
		modal.forceClose();
	}

	ModalStack.prototype.push = function(modal){
		this.remove(modal);
		appendListener(modal,this);
		this.modalStack.push(modal);
	}

	ModalStack.prototype.remove = function(modal){
		dropListener(modal,true);
		var i = this.modalStack.indexOf(modal);
		if(i!==-1){
			this.modalStack.splice(i,1);
		}
	}

	ModalStack.prototype.onShow = function(modal,event){
		for(var i = 0, len = this.modalStack.length;i<len;i++){
			var modalRef = this.modalStack[i];
			if(modal!== modalRef && modalRef.isShown()){
				callHide(modalRef,this);
			}
		}
	}

	ModalStack.prototype.onClose = function(modal,event){
		var openBefore = false;
		for(var i = this.modalStack.length - 1;i>-1;i--){
			var modalRef = this.modalStack[i];
			if(modal === modalRef){
				openBefore = true;
			}else if(openBefore){
				callShow(modalRef,this);
			}
		}
	}

	ModalStack.prototype.closeAll = function(){
		for(var i = this.modalStack.length - 1;i>-1;i--){
			var modalRef = this.modalStack[i];
			callClose(modalRef);
		}
		this.modalStack = [];
	};

	ModalStack.prototype.closeLast = function(){
		var last = true;
		for(var i = this.modalStack.length - 1;i>-1;i--){
			var modalRef = this.modalStack[i];
			if(last){
				callClose(modalRef);
				last = false;
			}else{
				callShow(modalRef,this)
				break;
			}
		}
	};
	return ModalStack;
});