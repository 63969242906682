/**
 * Converia.Registration.App
 */
Converia.Core.module('Converia.Registration.App', function () {

    /**
     * App
     * 
     * @param basicElement
     * @param config
     * @constructor
     */
	var App = function (basicElement, config) {
		var self = this;
		this.config = config;
		this.waitingForRequest = false;
		this.processIcon = new Converia.Core.ProcessIcon();
		this.messageHandler = new Converia.Core.MessageHandler();
		this.messageHandler.pushMessages(config.messages || []);
		this.modalStack = new Converia.Core.ModalStack();
		this.dialogHandler = new Converia.Registration.DialogHandler();
		this.formHandler = new Converia.Registration.FormHandler();
		this.collapseHelper = new Converia.Registration.CollapseHandler();
		this.cartHandler = new Converia.Registration.CartHandler(this.config.cartUrl);

		var proxy = $.proxy(this.triggerDialog, this);
		$(this.formHandler).on('open-dialog', proxy);

		var proxy = $.proxy(this.triggerDialogClose, this);
		$(this.formHandler).on('close-all-dialogs', proxy);

		var proxy = $.proxy(this.triggerModalClose, this);
		$(this.formHandler).on('close-modal', proxy);

		var proxy = $.proxy(this.refreshListener, this);
		$(this.formHandler).on('refresh-listener', proxy);
		$(this).on('refresh-listener', proxy);

		var proxy = $.proxy(this.triggerMessages, this);
		$(this.formHandler).on('show-messages', proxy);
		$(this.cartHandler).on('show-messages', proxy);
		$(this).on('show-messages', proxy);

		var proxy = $.proxy(this.processListener, this);
		$(this.formHandler).on('in-process', proxy);
		$(this.cartHandler).on('in-process', proxy);
		$(this).on('in-process', proxy);

		var proxy = $.proxy(this.triggerModalOpen, this);
		$(this.cartHandler).on('open-modal', proxy);

		var proxy = $.proxy(this.triggerRefreshView, this);
		$(this.formHandler).on('refresh-view', proxy);
		$(this.cartHandler).on('refresh-view', proxy);

		$(this.modalStack).on('before-open', function (event, modal) {
			self.appendHandler(modal.ele);
		});

        var proxy = $.proxy(this.onShowPackage, this);
        $(this.messageHandler).on('show-package', proxy);

        var proxy = $.proxy(this.onShowCategory, this);
        $(this.messageHandler).on('show-category', proxy);

        this.appendHandler(basicElement);
        this.startRedirectInterval();
    };

    /**
     * App.prototype.startRedirectInterval
     */
    App.prototype.startRedirectInterval = function () {
        var url = this.config.completeLogoutUrl;
        var timer = 60;
        var timerId = 0;
        var position = { x: 0, y: 0 };
        var messageHandler = new Converia.Core.MessageHandler();
        var cookie = getCookie('CFK_REGISTRATION_COUNTER_MODE');

        if (cookie !== 'undefined' && cookie) {
            var configuration = JSON.parse(decodeURIComponent(cookie));
        } else {
            configuration = {
                active: false
            }
        }

        if (configuration.active && this.config.currentstep != 'index' && this.config.currentstep != 'counter') {
            $(document).ready(function () {
                $(document).on("mousemove", function (event) {
                    if (position.x != event.clientX || position.y != event.clientY) {
                        timer = 60;
                        timerId = 0;
                    }

                    position = {
                        x: event.clientX,
                        y: event.clientY
                    };
                });
            });

            timerId = window.setInterval(function () {
                timer -= 1;

                if (timer <= 30 && timer > 0 && timer % 10 == 0) {
                    messageHandler.pushMessages(
                        [
                            {
                                type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_INFO,
                                message: 'This Session will be terminated in ' + timer + ' seconds due to inactivity.'
                            }
                        ]
                    );
                }

                if (timer <= 0) {
                    timer = 60;
                    window.clearInterval(timerId);
                    document.location.href = url;
                }
            }, 1000);
        }

        function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");

            if (parts.length == 2) {
                return parts.pop().split(";").shift();
            }
        }
    };

    /**
     * App.prototype.processListener
     * 
     * @param event
     * @param data
     */
	App.prototype.processListener = function (event, data) {
		if (data && data.end) {
			this.processIcon.hide();
		} else {
			this.processIcon.show();
		}
	};

    /**
     * App.prototype.scrollToError
     * 
     * @param baseElement
     */
	App.prototype.scrollToError = function (baseElement) {
		if ($(baseElement).hasClass('error')) {
			var $base = $(baseElement);
		} else {
			var $base = $(baseElement).find('.error');
		}

		if ($base.length == 0) {
			return;
		}

		var scrollableErrorHandling = $base.closest('[data-scrollable-errors]');

		if (scrollableErrorHandling.length == 0) {
			return;
		}

		var ref = scrollableErrorHandling.data('scrollable-errors');
		var parentRef = $(ref);

		if (ref.match('body') && ref.match('html')) {
			var scrollTop = $base.offset().top - 30
		} else {
			var $ele = $base;
			var $lastEle = $ele;
			var found = false;
			var scrollTop = $ele.position().top;

			while(($ele = $ele.offsetParent()) && $ele.length) {
				if ($ele.get(0) == parentRef.get(0)) {
					found = true;
					break;
				}

				scrollTop += $ele.position().top;

				if ($lastEle.get(0) == $ele.get(0)) {
					break;
				}

				$lastEle = $ele;
			}

			if (!found) {
				return;
			}

			scrollTop -= 30;
		}

		parentRef.animate({
			scrollTop: scrollTop
		}, 500);
	};

	var hasScrollBar = function (ele) {
		return ele ? ele.scrollHeight > $(ele).innerHeight() : false;
	};

    /**
     * App.prototype.triggerRefreshView
     * 
     * @param event
     * @param data
     */
	App.prototype.triggerRefreshView = function (event, data) {
		if (this.waitingForRequest) {
			this.messageHandler.pushMessages(
			    [
			        {
			            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                        message: 'Unfinish request running.'
			        }
                ]
            );

			return;
		}

		var url = this.config.refreshUrl;

		if (url) {
			var success = $.proxy(this.onRefreshSuccess, this);
			var error = $.proxy(this.onRefreshError, this);

			new Converia.Core.AjaxRequest(
				url,
				{},
				{})
				.then(success)
				.else(error)
				.send();

			this.waitingForRequest = true;
			$(this).trigger('in-process');
		}
	};

    /**
     * App.prototype.onRefreshSuccess
     * 
     * @param data
     */
	App.prototype.onRefreshSuccess = function ( data) {
		this.waitingForRequest = false;

		$(this).trigger('in-process', {
		    end: true
		});

		if (data.messages) {
			this.messageHandler.pushMessages(data.messages);
		}

		if (data.content) {
			var content = $(data.content).filter('[data-converia-module]').get(0);

			$('[data-converia-module]').replaceWith(content);

			$(this).trigger('refresh-listener', {
			    element: content
			});
		}
	};

    /**
     * App.prototype.onRefreshError
     * 
     * @param data
     */
	App.prototype.onRefreshError = function ( data) {
		this.waitingForRequest = false;

		$(this).trigger('in-process', {
		    end: true
		});

		if (data.connectionError) {
			this.messageHandler.pushMessages(
			    [
			        {
			            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                        message: 'Connection to server not possible.'
			        }
                ]
            );

			return;
		}

		if (data.messages) {
			this.messageHandler.pushMessages(data.messages);
		}
	};

    /**
     * App.prototype.triggerMessages
     * 
     * @param event
     * @param data
     */
	App.prototype.triggerMessages = function (event, data) {
		this.messageHandler.pushMessages(data.messages || []);
	};

    /**
     * App.prototype.triggerDialogClose
     * 
     * @param event
     * @param data
     */
	App.prototype.triggerDialogClose = function (event, data) {
		this.modalStack.closeAll();
	};

    /**
     * App.prototype.triggerDialog
     * 
     * @param event
     * @param data
     */
	App.prototype.triggerDialog = function (event, data) {
		var dialog = new Converia.Core.Dialog(
            data.dialog.title,
			data.dialog.content,
            data.dialog.buttons,
			data.dialogOptions,
			this.modalStack
		);

		var proxy = $.proxy(this.dispatchDialogDecision, this);

		$(dialog).on('dialog-action',{dialog:dialog}, proxy);
		this.appendHandler(dialog.ele);
	};

    /**
     * App.prototype.dispatchDialogDecision
     * 
     * @param event
     * @param data
     */
	App.prototype.dispatchDialogDecision = function (event, data) {
		var actionKey = data.actionKey;
		var dialog = event.data.dialog;
		this.dialogHandler.dispatchDialogDecision(actionKey, dialog);
	};

    /**
     * App.prototype.triggerModalClose
     * 
     * @param event
     */
	App.prototype.triggerModalClose = function (event) {
		this.modalStack.closeLast();
	};

    /**
     * App.prototype.triggerModalOpen
     * 
     * @param event
     * @param data
     */
	App.prototype.triggerModalOpen = function (event, data) {
		var config = data.config || {};

		this.modalStack.closeAll();

		var modalDialog = new Converia.Core.Modal(
			this.modalStack
		);

		modalDialog.open(
			data.content,
			config
		);
	};

    /**
     * App.prototype.refreshListener
     * 
     * @param event
     * @param data
     */
	App.prototype.refreshListener = function (event, data) {
		if (data.element) {
			this.appendHandler(data.element);
		}
	};

    /**
     * App.prototype.appendHandler
     * 
     * @param basicElement
     */
	App.prototype.appendHandler = function (basicElement) {
		var self = this;

		$(basicElement).find('[data-counter-handler]').each(function () {
		    var handler = new Converia.Registration.CounterModeHandler(
		        $(this).data('counter-handler')
            );
  
            var proxy = $.proxy(self.triggerMessages, self);
  
            $(handler).on('show-messages', proxy);
		    handler.run();
        });

		$(basicElement).find('[data-modal]').on('click', function (event) {
            var config = $(this).data('modal-config') || {};

            self.modalStack.closeAll();

            var modalDialog = new Converia.Core.Modal(
                self.modalStack
            );

            modalDialog.open(this, config);
            event.preventDefault();
        });

        $(basicElement).find('[data-modal-iframe]').on('click', function (event) {
            var config = $(this).data('modal-config') || {};

            self.modalStack.closeAll();
            var modalDialog = new Converia.Core.IframeModal(
                self.modalStack
            );
            modalDialog.open(
                $(this).data('modal-iframe'),
                null,
                config
            );
            event.preventDefault();
        });

        var companyList = this.config.companyList || [];

        $(basicElement).find('[data-autocomplete="company"]').each(
            function () {
                new Converia.Core.CompanyProposal(this,companyList);
            }
        );

        $(basicElement).find('input[name="payment"]:checked').each(
            function () {
                if ($(this).data('payment-testmode') == '1') {
                    $('#payment-testmode-alert').removeClass('hide')
                } else {
                    $('#payment-testmode-alert').addClass('hide')
                }
            }
        );
        
		$(basicElement).find('[data-change-action]').on('change', function (event) {
            var $ele = $(this);

            switch($ele.data('change-action')) {
                case 'offerAmountSetting':
                    self.formHandler.onChangeElementAmount($ele);
                    break;
                case 'changePricegroup':
                    self.formHandler.onChangePricegroup($ele);
                    break;
                case 'setStartTime':
                    self.formHandler.onChangeHotelTime($ele);
                    break;
                case 'setEndTime':
                    self.formHandler.onChangeHotelTime($ele);
                    break;
            }
        });

		$(basicElement).find('[data-click-action]').on('click', function (event) {
				var $ele = $(this);

				switch($ele.data('click-action')) {
					case 'forwardOfferFinish':
						var url = $ele.data('action-url');
						document.location = url;
						break;
					case 'addOnePackage':
						var id = '[data-component-id=' + $ele.data('form-ref') + ']';
						$(id).trigger('submit');
						break;
					case 'setPayment':
						self.formHandler.setPayment($ele);
						break;
					case 'finishBooking':
						self.formHandler.finishBooking($ele);
                        event.preventDefault();
						break;
					case 'collapse':
						self.collapseHelper.toggle(this);
                        event.preventDefault();
						break;
					case 'openCollapse':
						var id = '#' + $ele.data('ref');
						$(id).trigger('click');
						break;
					case 'deleteItem':
						var url = $ele.data('action-url');
						self.cartHandler.removeItem(url);
						event.preventDefault();
						break;
					case 'decreaseOfferAmount':
						var id = $ele.data('amount-reference');
						var $ref = $('#' + id);
						self.formHandler.changeElementAmount($ref,false);
                        event.preventDefault();
						break;
					case 'increaseOfferAmount':
						var id = $ele.data('amount-reference');
						var $ref = $('#' + id);
						self.formHandler.changeElementAmount($ref,true);
                        event.preventDefault();
						break;
					case 'showCartContent':
						self.cartHandler.open();
                        event.preventDefault();
						break;
					case 'toggleContainerVisibilityWithNag':
						var id = $ele.data('nag-reference');
						var action = $ele.data('nag-action');
						self.formHandler.onToggleNagVisibility(id, action);
						var id = $ele.data('container-reference');
						var action = $ele.data('container-action');
						self.formHandler.onToggleContainerVisibility(id, action);
						break;
                    case 'toggleContainerVisibility':
                        var id = $ele.data('container-reference');
                        var action = $ele.data('container-action');
                        self.formHandler.onToggleContainerVisibility(id, action);
                        break;
                    case 'submit':
                        var id = '[data-component-id=' + $ele.data('form-ref') + ']';
                        $ele.attr('disabled', true);
                        $(id).trigger('submit');
                        event.preventDefault();
                        break;
                    case 'takeAdminContact':
                        var ref = $ele.data('data-reference');
                        self.formHandler.assignTemplates(basicElement, ref);
                        self.formHandler.onTakeAdminContact();
                        event.preventDefault();
                        break;
                    case 'assignTemplates':
                        var ref = $ele.data('data-reference');
                        self.formHandler.assignTemplates(basicElement, ref);
                        event.preventDefault();
                        break;
                    case 'hideGroupAdminContainer':
                        var id = $ele.data('container-reference');
                        self.formHandler.hideContainer(id);
                        var url = $ele.data('action-url');
                        self.formHandler.onIgnoreGroupAdminContact(url);
                        event.preventDefault();
                        break;
                    case 'exportConfirmation':
                        self.onConfirmationsExport(this);
                        event.preventDefault();
                        break;
                    case 'deleteParticipant':
                        var d = $ele.data('dialog');
                        var url = $ele.data('delete-url');

                        self.dialogHandler.setCurrentUrl(url);

                        var dialog = new Converia.Core.Dialog(
                            d.title,
                            d.content,
                            d.buttons,
                            {},
							self.modalStack
                        );

                        var proxy = $.proxy(self.dispatchDialogDecision, self);

                        $(dialog).on('dialog-action', {
                            dialog: dialog
                        }, proxy);

                        self.appendHandler(dialog.ele);
                        event.preventDefault();
                        break;
					case 'preselect-abort':
						var url = $ele.data('url');

						self.processIcon.show();
						new Converia.Core.AjaxRequest(
							url,
							{},
							{})
							.then(function (result) {
								self.processIcon.hide();
								self.modalStack.closeAll();

								if (result.content) {
									var content = $(result.content).filter('[data-converia-module]').get(0);
									$('[data-converia-module]').replaceWith(content);
									self.appendHandler(content);
								}
							})
							.else(function () {
								self.processIcon.hide();
								self.modalStack.closeAll();
								self.messageHandler.pushMessages(
								    [
								        {
								            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                                            message: 'Connection to server not possible.'
								        }
                                    ]
                                );
							})
							.send();
						break;
                    case 'show-offer':
                        var url = $ele.data('action-url');
                        document.location = url;
                        break;
					default:
                        break;
				}
                return true;
			}
		);

        $(basicElement).find('[name="invoice[contact_invoice_switch]"]:checked').click();
        $(basicElement).find('[name="contact_account_switch"]:checked').click();

		this.collapseHelper.keepOpen($(basicElement).find('[data-click-action=collapse]'));

		$(basicElement).find('[data-popover-content]').on('click', function (event) {
				event.preventDefault();
				var options = $.extend(
				    {
                        addClose: true
                    },
                    $(this).data('popover-config')
                );

				var popover = new Converia.Core.Popover(
					this,
					options
				);

				popover.toggle();

				var tip = popover.getTip();

				if (tip) {
					self.appendHandler(tip);
				}
			}
		);

		$(basicElement).find('[data-modal-autoopen]').trigger('click');
		$(basicElement).find('[data-pricegroup-verification]').on('click', function () {
			self.formHandler.onSetPricegroup();
		});

		self.formHandler.onSetPricegroup();

		this.scrollToError(basicElement);

		var onAjaxFormSubmitProxy = $.proxy(this.formHandler.onAjaxFormSubmit, this.formHandler);

		$(basicElement).find('[data-ajax-form]').on('submit', onAjaxFormSubmitProxy);

		$(basicElement).find('[data-hint]').tooltip({
				trigger:'hover',
				container:false,
				placement:function () {
					if (this.$element.data('placement')) {
						return this.$element.data('placement');
					}

					return 'top'
				}
			});

		$(basicElement).filter('[data-ajax-form]').on('submit', onAjaxFormSubmitProxy);

        var onCheckEmailForLogin = $.proxy(this.onCheckEmailForLogin, this);
        $(basicElement).find('[data-check-email]').on('focusout', onCheckEmailForLogin);

        var onEditPerson = $.proxy(this.onEditPerson, this);
        $(basicElement).find('[data-edit-person]').on('click', onEditPerson);

        var onShowOffer = $.proxy(this.onShowOffer, this);
        $(basicElement).find('[data-show-offer]').on('click', onShowOffer);

        var onCopyCart = $.proxy(this.onCopyCart, this);
        $(basicElement).find('[data-copy-cart]').on('click', onCopyCart);


        $(basicElement).find('.cart-container').each(
            function(){
                var cartContainer = $(basicElement).find('.cart-container');
                var stickyContainer = $(basicElement).find('.sticky-container');
                
                $(window).off('scroll.sticky');
                $(window).on('scroll.sticky',function () {
                    var viewPortBottomPosition = $(window).scrollTop() + $(window).height()
                    var cartContainerTop = cartContainer.offset().top;
                    var cartContainerHeight = cartContainer.outerHeight();
                    var cartContainerCurrentBottom = cartContainerTop + cartContainerHeight;
                    var stickyContainerTop = stickyContainer.offset().top;

                    stickyContainer.css({
                        'height': cartContainerHeight
                    })

                    if (cartContainerTop >= stickyContainerTop) {
                        cartContainer.removeClass('sticky').addClass('sticky-surpassed');
                    }

                    if (viewPortBottomPosition < cartContainerCurrentBottom) {
                        cartContainer.removeClass('sticky-surpassed').addClass('sticky');
                    }

                });
            }
        );
	};

    /**
     * App.prototype.onCheckEmailForLogin
     * 
     * @param event
     */
    App.prototype.onCheckEmailForLogin = function (event) {
        var self = this;
        var ele = event.target;
        var url = $(ele).data('check-email');
        var success = function (result) {
            if (result.success) {
                var content = result.content;

				self.modalStack.closeAll();
				var modalDialog = new Converia.Core.Modal(
					self.modalStack
				);
				modalDialog.open(
					content,
					{}
				);
            }
        };

        new Converia.Core.AjaxRequest(
            url,
            {mailTemplate:$(ele).val()},
            {})
            .then(success)
            .else(function () {

            })
            .send();
    };

    /**
     * App.prototype.onEditPerson
     * 
     * @param event
     */
    App.prototype.onEditPerson = function (event) {
        event.preventDefault();
        var self = this;
        var ele = event.target;
        var url = $(ele).data('ajax-url');
        var id = $(ele).data('edit-person');
        var success = function (result) {
            if (result.success) {
                var content = result.content;

				self.modalStack.closeAll();

				var modalDialog = new Converia.Core.Modal(
					self.modalStack
				);

				modalDialog.open(
					content,
					{}
				);
            }
        };

        new Converia.Core.AjaxRequest(
            url,
            {bid:id},
            {})
            .then(success)
            .else(function (res) {
				if (res.messages) {
					self.messageHandler.pushMessages(res.messages || []);
				}
            })
            .send();
    };

    /**
     * App.prototype.onShowOffer
     * 
     * @param event
     */
    App.prototype.onShowOffer = function (event) {
        event.preventDefault();
        var self = this;
        var ele = event.target;
        var url = $(ele).data('ajax-url');
        var success = function (result) {
            console.log(result);

            if (result.success) {
                if (result.redirect) {
                    document.location = result.redirect;
                }
            }
        };

        new Converia.Core.AjaxRequest(url)
            .then(success)
            .else(function (res) {
                if (res.messages) {
                    self.messageHandler.pushMessages(res.messages || []);
                }
            })
            .send();
    };

    /**
     * App.prototype.onConfirmationsExport
     * 
     * @param element
     */
    App.prototype.onConfirmationsExport = function (element) {
        var self = this;
        var $ele = $(element);
        var url = $ele.data('ajax-url');
        var helper = new ConveriaFE.MassActionHelper(url,"ConfirmationExport", []);
  
        $(helper).on("running", function (event, data) {});
 
        $(helper).on("ready", function (event, data) {
            document.location = url+'&finished=1';
        });
 
        $(helper).on("error", function (event, data) {
            if (data.msg) {
                self.messageHandler.pushMessages([{
                    type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                    message: data.msg
                }]);
            }
        });

        helper.run();
    };

    /**
     * App.prototype.onCopyCart
     * 
     * @param event
     */
    App.prototype.onCopyCart = function (event) {
        event.preventDefault();
        var self = this;
        var ele = event.target;
        var url = $(ele).data('ajax-url');
        var id = $(ele).data('copy-cart');
        var success = function (result) {
            if (result.success) {
                var content = result.content;

				self.modalStack.closeAll();

				var modalDialog = new Converia.Core.Modal(
					self.modalStack
				);

				modalDialog.open(content, {});
            }
        };

        new Converia.Core.AjaxRequest(
            url,
            {bid:id},
            {})
            .then(success)
            .else(function (res) {
                if (res.messages) {
                    self.messageHandler.pushMessages(res.messages || []);
                }
            })
            .send();
    };

    /**
     * App.prototype.onShowPackage
     * 
     * @param event
     * @param packageId
     */
    App.prototype.onShowPackage = function(event, packageId){
        var element = $('[data-component-id="package-' + packageId + '"]').get(0);
 
        if (element) {
            $('html, body').animate({
                scrollTop:$(element).offset().top
            }, 'slow');
        }
    };

    /**
     * App.prototype.onShowCategory
     * 
     * @param event
     * @param packageId
     */
    App.prototype.onShowCategory = function(event, packageId){
        var element = $('[data-component-id="category-' + packageId + '"]').get(0);
 
        if (element) {
            $('html, body').animate({
                scrollTop:$(element).offset().top
            }, 'slow');
        }
    };

    return App;
});
