Converia = {
    Core: {
        module: function (moduleName, cb) {
            var module = cb();
            var namespace = moduleName.split(".");
            var currentReference = window;

            for (var i = 0, len = (namespace.length); i < len; i++) {
                if (len - 1 == i) {
                    currentReference[namespace[i]] = module;
                } else if (typeof(currentReference[namespace[i]]) == 'undefined') {
                    currentReference[namespace[i]] = {};
                }
                currentReference = currentReference[namespace[i]];
            }
        },

        initApp: function (moduleName, basicElement, config) {
            var namespace = moduleName.split(".");
            var currentReference = window;

            for (var i = 0, len = (namespace.length); i < len; i++) {
                if (len - 1 == i) {
                    return new currentReference[namespace[i]](basicElement, config);
                }

                currentReference = currentReference[namespace[i]];
            }

            return null;
        }
    }
};