/**
 * Converia VSB FavoriteHandler
 *
 * Converia.Core.module
 */
Converia.Core.module('Converia.VSB.FavoriteHandler', function () {
    /**
     * FavoriteHandler
     * 
     * @param loginHandler
     * @param conferenceConfig
     * @constructor
     */
    var FavoriteHandler = function (loginHandler, conferenceConfig) {
        var self = this;

        this.currentType = false;
        this.currentId = false;
        this.currentMode = false;
        this.url = conferenceConfig.urls.favorites;
        this.favorites = [];
        this.login = loginHandler;
        this.login.checkLogin();

        $(this.login).on('is-logged-in', function () {
            if (self.currentType) {
                self.synchFavorites();
            } else {
                self.loadFavorites();
            }
        });
    };

    /**
     * FavoriteHandler.prototype.setFavorites
     * 
     * @param type
     * @param id
     * @param setFavorite
     */
    FavoriteHandler.prototype.setFavorites = function (type, id, setFavorite) {
        this.currentType = type;
        this.currentId = id;
        this.currentMode = setFavorite;

        if (!this.login.isLoggedIn()) {
            this.login.show();
        } else {
            this.synchFavorites();
        }
    };

    /**
     * FavoriteHandler.prototype.synchFavorites
     */
    FavoriteHandler.prototype.synchFavorites = function () {
        if (this.currentType) {
            var self = this;

            $(this).trigger('start-synch');

            $.ajax({
                type: "POST",
                url: this.url.replace(new RegExp(/__dummy_action__/, 'g'), 'set-favorites'),
                data: {
                    type: this.currentType,
                    id: this.currentId,
                    mode: this.currentMode
                },
                success: function (result) {
                    if (result.success) {
                        self.favorites = result.favorites;
                        self.refreshFavoriteView();
                    } else {
                        if (result.errors[0] == 'login_error') {
                            self.login.refreshLogin();
                        }
                    }
                    $(self).trigger('end-synch');
                },
                error: function () {
                    self.handleError();
                    $(self).trigger('end-synch');
                },
                dataType: 'json'
            });

            this.currentType = false;
            this.currentId = false;
            this.currentMode = false;
        }
    };

    /**
     * FavoriteHandler.prototype.loadFavorites
     */
    FavoriteHandler.prototype.loadFavorites = function () {
        var self = this;
        $.ajax({
            type: "POST",
            url: this.url.replace(new RegExp(/__dummy_action__/, 'g'), 'get-favorites'),
            data: {},
            success: function (result) {
                if (result.success) {
                    self.favorites = result.favorites;
                    self.refreshFavoriteView();
                } else {
                    if (result.errors[0] == 'login_error') {
                        self.login.refreshLogin();
                    }
                }
            },
            error: function () {
                self.handleError();
            },
            dataType: 'json'
        });
    };

    /**
     * FavoriteHandler.prototype.refreshFavoriteView
     */
    FavoriteHandler.prototype.refreshFavoriteView = function () {
        $('span[data-favorite-setter]').removeClass('fe-icon-star-filled').removeClass('disabled').addClass('fe-icon-star');

        for (var i = 0, len = this.favorites.length; i < len; i++) {
            var ele = $('span[data-ident="' + this.favorites[i] + '"]');

            ele.removeClass('fe-icon-star').addClass('fe-icon-star-filled');

            if (this.favorites[i].match(/^2_/)) {
                var parent = ele.parent();
                parent.find('span[data-favorite-setter][data-ident^=5_]').addClass('disabled');
            }
        }
    };

    /**
     * FavoriteHandler.prototype.handleError
     */
    FavoriteHandler.prototype.handleError = function () {
    };
    
    return FavoriteHandler;
});