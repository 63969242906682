Converia.Core.module('Converia.Core.MobileRedirect', function () {
    var yes = 'y';
    var no = 'n';
    var isMobile = function () {
        if (window.matchMedia) {
            var mq = window.matchMedia('only all and (max-width: 40rem)');
            return mq.matches;
        }
        return false;
    };

    var shouldAsk = function (cookieName) {
        var decision = $.cookie(cookieName);
        console.log(cookieName, decision, typeof(decision));

        if (typeof(decision) === 'undefined') {
            return true;
        }

        return false;
    };

    var shouldRedirect = function (cookieName) {
        var decision = $.cookie(cookieName);

        if (decision === yes) {
            return true;
        }

        return false;
    };

    var storeDecision = function (cookieName, decision) {
        $.cookie(cookieName, decision);
    };

    var MobileRedirect = function (destination, text, cookieName) {
        this.destination = destination;
        this.text = text;
        this.cookieName = cookieName;
    };

    MobileRedirect.prototype.init = function () {
        if (isMobile()) {
            if (shouldAsk(this.cookieName)) {
                this.ask();
            } else if (shouldRedirect(this.cookieName)) {
                this.redirect();
            }
        }
    };

    MobileRedirect.prototype.ask = function () {
        if (confirm(this.text)) {
            storeDecision(this.cookieName, yes);
            this.redirect();
        } else {
            storeDecision(this.cookieName, no);
        }
    };

    MobileRedirect.prototype.redirect = function () {
        document.location = this.destination;
    };
    return MobileRedirect;
});