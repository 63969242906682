Converia.Core.module('Converia.Core.PrintAdapter', function () {
    var defaults = {
        endpoint: "https://printservice.local:8081"
    };

    var PrintAdapter = function (config) {
        this.config = $.extend(defaults, config);
    };

    /**
     * Loads a list of printers
     * @return Converia.Core.Promise
     */
    PrintAdapter.prototype.getList = function () {
        var url = this.config['endpoint'] + '/printerList';
        var promise = new Converia.Core.Promise();

        $.ajax({
            url: url,
            type: "POST",
            data: {},
            cache: false,
            success: function (result) {
                promise.resolve(result);
            },
            error: function (result) {
                promise.reject({connectionError: true, data: result});
            },
            dataType: "json"
        });

        return promise;
    };

    /**
     * Try to print a job
     * @param string printerName
     * @param string type
     * @param string document base64 encoded
     * @return Converia.Core.Promise
     */
    PrintAdapter.prototype.print = function (printerName, type, document) {
        var url = this.config['endpoint'] + '/print';
        var content = {
            printerName: printerName,
            type: type,
            document: document
        };

        var promise = new Converia.Core.Promise();

        $.ajax({
            url: url,
            type: "POST",
            data: JSON.stringify(content),
            cache: false,
            success: function (result) {
                if (result.state == 'success') {
                    promise.resolve(result);
                } else {
                    promise.reject({connectionError: false, data: result});
                }

            },
            error: function (result) {
                promise.reject({connectionError: true, data: result});
            },
            dataType: "json"
        });

        return promise;
    };

    return PrintAdapter;
});