/**
 * Converia Registration CartHandler
 */

Converia.Core.module('Converia.Registration.CartHandler', function () {
    var CartHandler = function (url) {
        this.url = url;
    };

    /**
     * CartHandler.prototype.open
     */
    CartHandler.prototype.open = function () {
        var self = this;

        new Converia.Core.AjaxRequest(
                this.url,
                {},
                {}
            )
            .then(function (res) {
                $(self).trigger('in-process', {end: true});

                if (res.messages) {
                    $(self).trigger('show-messages', {
                        messages: res.messages
                    });
                }

                if (res.content) {
                    $(self).trigger('open-modal', {
                        content: res.content,
                        config: {
                            addClass: 'overlay-modal-cart'
                        }
                    });
                }

                if (res.redirect) {
                    document.location = res.redirect;
                }
            })
            .else(function (res) {
                $(self).trigger('in-process', {end: true});
                $(self).trigger('show-messages', {
                    messages: [{
                        type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                        message: 'Cart could not be loaded.'
                    }]
                });
            })
            .send();
        $(this).trigger('in-process');
    };

    /**
     * CartHandler.prototype.removeItem
     * 
     * @param url
     */
    CartHandler.prototype.removeItem = function (url) {
        var self = this;

        new Converia.Core.AjaxRequest(url, {}, {})
            .then(function (res) {
                $(self).trigger('in-process', {
                    end: true
                });

                if (res.messages) {
                    $(self).trigger('show-messages', {
                        messages: res.messages
                    });
                }

                if (res.content) {
                    $(self).trigger('open-modal', {
                        content: res.content,
                        config: {}
                    });
                }

                if (res.redirect) {
                    document.location = res.redirect;
                }

                $(self).trigger('refresh-view');
            })
            .else(function (res) {
                $(self).trigger('in-process', {
                    end: true
                });

                if (res.connectionError) {
                    $(self).trigger('show-messages', {
                        messages: [{
                            type: Converia.Core.MessageHandler.MSG_TYPES.MSG_TYPE_ERROR,
                            message: 'Connection to server not possible.'
                        }]
                    });

                    return;
                }

                if (res.messages) {
                    $(self).trigger('show-messages', {messages: res.messages});
                }
            })
            .send();
        $(this).trigger('in-process');
    };

    return CartHandler;
});