Converia.Core.module('Converia.Core.Promise', function () {
    var Promise = function () {
        this.onSuccess = null;
        this.onError = null;
    };

    Promise.prototype.then = function (cb, context) {
        if (typeof(context) === 'undefined') {
            context = this;
        }

        this.onSuccess = [cb, context];

        return this;
    };

    Promise.prototype.else = function (cb, context) {
        if (typeof(context) === 'undefined') {
            context = this;
        }

        this.onError = [cb, context];

        return this;
    };

    Promise.prototype.resolve = function (params) {
        if (!this.onSuccess) {
            return;
        }

        try {
            var cb = this.onSuccess[0];
            var context = this.onSuccess[1];

            cb.call(context, params);
        } catch (e) {
            throw e;
        }
    };

    Promise.prototype.reject = function (params) {
        if (!this.onError) {
            return;
        }

        try {
            var cb = this.onError[0];
            var context = this.onError[1];
            cb.call(context, params);
        } catch (e) {
            throw e;
        }
    };

    return Promise;
});