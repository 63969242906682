/**
 * Converia Registration CollapseHandler
 */

Converia.Core.module('Converia.Registration.CollapseHandler', function () {
    /**
     * CollapseHandler
     * 
     * @constructor
     */
    var CollapseHandler = function () {
        this.openElements = [];
    };

    /**
     * open
     * 
     * @param $ele
     * @param openElements
     */
    var open = function ($ele, openElements) {
        var controlledEle = $ele.attr('aria-controls');
        var $openedElement = $('#' + controlledEle);
        var wrapperEle = $ele.data('wrapper-reference');
        var $wrapper = $('#' + wrapperEle);

        // set aria attribtes
        $ele.attr({
            'aria-selected': 'true',
            'aria-expanded': 'true'
        });

        $openedElement.attr({
            'aria-hidden': 'false'
        });

        $wrapper.addClass('open');
        openElements.push($ele.attr('id'));
        $ele.data('open', true);
    };

    /**
     * close
     * 
     * @param $ele
     * @param openElements
     */
    var close = function ($ele, openElements) {
        var controlledEle = $ele.attr('aria-controls');
        var $openedElement = $('#' + controlledEle);
        var wrapperEle = $ele.data('wrapper-reference');
        var $wrapper = $('#' + wrapperEle);

        // set aria attribtes
        $ele.attr({
            'aria-selected': 'false',
            'aria-expanded': 'false'
        });

        $openedElement.attr({
            'aria-hidden': 'true'
        });

        $wrapper.removeClass('open');

        var id = $ele.attr('id');

        for (var i = 0, len = openElements.length; i < len; i++) {
            if (openElements[i] == id) {
                openElements.splice(i, 1);
                i--;
            }
        }

        $ele.data('open', false);
    };

    /**
     * isMarkedOpen
     * 
     * @param openElements
     * @param id
     * @returns {boolean}
     */
    var isMarkedOpen = function (openElements, id) {
        for (var i = 0, len = openElements.length; i < len; i++) {
            if (openElements[i] == id) {
                return true;
            }
        }

        return false;
    };

    /**
     * CollapseHandler
     * 
     * @param $eles
     */
    CollapseHandler.prototype.keepOpen = function ($eles) {
        for (var i = 0, len = $eles.length; i < len; i++) {
            var id = $($eles[i]).attr('id');

            if (isMarkedOpen(this.openElements, id)) {
                open($($eles[i]), this.openElements);
            } else if ($($eles[i]).data('has-errors')) {
                open($($eles[i]), this.openElements);
            }
        }
    };

    /**
     * CollapseHandler
     * 
     * @param ele
     */
    CollapseHandler.prototype.toggle = function (ele) {
        var $toggleElement = $(ele);

        if ($toggleElement.data('open')) {
            close($toggleElement, this.openElements);
        } else {
            open($toggleElement, this.openElements);
        }
    };

    return CollapseHandler;
});