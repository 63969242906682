/**
 * Converia.Core.AjaxRequest
 */
Converia.Core.module('Converia.Core.AjaxRequest', function () {
    var AjaxRequest = function (url, params, config) {
        Converia.Core.Promise.call(this);
 
        var defaults = {};
 
        this.url = url;
        this.params = params;
        this.config = $.extend(defaults, config);
    };

    AjaxRequest.prototype = Object.create(Converia.Core.Promise.prototype, {});
    AjaxRequest.prototype.constructor = AjaxRequest;
    AjaxRequest.prototype.send = function () {
        var self = this;

        $.ajax({
            url: this.url,
            type: "POST",
            data: this.params,
            cache: false,

            /**
             * success
             * 
             * @param result
             */
            success: function (result) {
                if (result.success) {
                    self.resolve(result);
                } else {
                    self.reject(result);
                }
            },

            /**
             * error
             * 
             * @param result
             */
            error: function (result) {
                self.reject({connectionError: true, data: result});
            },
            dataType: "json"
        });

        return this;
    };

    /**
     * use this method when files will be submitted via ajax
     * 
     * @returns {AjaxRequest}
     */
    AjaxRequest.prototype.sendAsFormData = function () {
        var self = this;

        $.ajax({
            url: this.url,
            type: "POST",
            data: this.params,
            contentType: false,
            processData: false,  // Important!
            cache: false,

            /**
             * success
             * 
             * @param result
             */
            success: function (result) {
                if (result.success) {
                    self.resolve(result);
                } else {
                    self.reject(result);
                }
            },

            /**
             * error
             * 
             * @param result
             */
            error: function (result) {
                self.reject({connectionError: true, data: result});
            }
        });

        return this;
    };

    return AjaxRequest;
});