/**
 * Created by flechsig on 14.11.2017.
 */

Converia.Core.module('Converia.Core.WordCharCounter', function () {

    var defaults = {
        type: 'char',                       // Do we count chars or words?
        length: 100,                        // How many of them are allowed?
        number_text: 'Number of chars: ',   // Text displayed before the counts
        alert_text: 'Too Many chars',       // Message when we have too cut (only appearing when force_cut = true)
        force_cut: false                    // If this is true it wont be possible too enter too much
    };

    var WordCharCounter = function (ele, options) {
        this.config = $.extend(defaults, options);
        this.ele = ele;

        var self = this;
        var $anElement = $('<div class="textarea-append-wrapper"></div>');

        WordCharCounter.template = '' +
            '<div class="textarea-append">' +
            '   <div class="counter" id="counter-wrapper">' +
            '       <span class="counter-label" id="counter-label">' + this.config.number_text + '</span>' +
            '       <span class="counter-count" id="character-counter"></span>' +
            '   </div>' +
            '</div><!-- /.textarea-append -->';

        $(this.ele).replaceWith($anElement);

        $anElement.append(this.ele);
        $anElement.append(WordCharCounter.template);
        self.onKeyUp();

        $(ele).on('keyup', function (event) {
            self.onKeyUp(event);
        });
    };

    WordCharCounter.prototype.getLength = function (content) {
        var length = 0;

        if (this.config.type == 'char') {
            length = content.length;
        } else if (this.config.type == 'word') {
            if (content) {
                var regex = /\s+/gi;
                length = content.trim().replace(regex, ' ').split(' ').length;
            }
        }

        return length;
    };

    WordCharCounter.prototype.onKeyUp = function (event) {
        var content = $(this.ele).val();

        if (this.config.force_cut) {
            this.cutInput(content);
            var content = $(this.ele).val();
        }

        var length = this.getLength(content);

        $("#character-counter").text(length + "/" + this.config.length);

        if (!this.config.force_cut) {
            if (length > this.config.length) {
                $("#character-counter").addClass("warning");
                $("#counter-label").addClass("warning");
            } else {
                $("#character-counter").removeClass("warning");
                $("#counter-label").removeClass("warning");
            }
        }
    };

    WordCharCounter.prototype.cutInput = function (content) {
        if (this.config.type == 'char') {
            if (this.getLength(content) > this.config.length) {
                $(this.ele).val(content.substr(0, this.config.length));
                alert(this.config.alert_text);
            }
        } else if (this.config.type == 'word') {
            var regex = /\s+/gi;

            tempContent = content.trim().replace(regex, ' ').split(' ');

            if (tempContent.length > this.config.length) {
                tempContent = tempContent.slice(0, this.config.length).join(' ');
                $(this.ele).val(tempContent);
                alert(this.config.alert_text);
            }
        }
    };

    return WordCharCounter;
});