/**
 * Converia VSB App
 * 
 * Converia.Core.module
 */
Converia.Core.module('Converia.VSB.App', function () {

    /**
     * App
     * 
     * @param basicElement
     * @param config
     * @constructor
     */
    var App = function (basicElement, config) {
        if (config.mobileUrl) {
            var redirect = new Converia.Core.MobileRedirect(
                config.mobileUrl,
                config.mobileQuestion,
                'Converia.VSB.App'
            );

            redirect.init();
        }
        var self = this;
        this.modalStack = new Converia.Core.ModalStack();
        var loginHandler = new Converia.VSB.LoginHandler(config);

        $(loginHandler).on('start-login', function () {
            processIcon.show();
        });

        $(loginHandler).on('end-login', function () {
            processIcon.hide();
        });

        var favoriteHandler = new Converia.VSB.FavoriteHandler(loginHandler, config);

        $(favoriteHandler).on('start-synch', function () {
            processIcon.show();
        });

        $(favoriteHandler).on('end-synch', function () {
            processIcon.hide();
        });

        $('.schedule_wrapper').on('click', 'span[data-ical-link]', function (event) {
            var url = $(this).data('ical-link');

            document.location.href = url;

            event.stopPropagation();
            event.preventDefault();
        });

        $('.schedule_wrapper').on('click', 'span[data-favorite-setter]:not(.disabled)', function (event) {
            var ident = $(this).data('ident').split('_');
            var type = ident[0];
            var id = ident[1];
            var setFavorite = 0;

            if ($(this).hasClass('fe-icon-star')) {
                setFavorite = 1;
            }

            favoriteHandler.setFavorites(type, id, setFavorite);
            event.stopPropagation();
            event.preventDefault();
        });


        /**
         * Show paper detail in overlay
         */
        $('a[data-overlay-content]').click(function () {
            var ele = this;
            var overlayHeader = $(ele).data('overlay-header');
            var overlayContent = $(ele).data('overlay-content');

            self.modalStack.closeAll();

            var modalDialog = new Converia.Core.IframeModal(
                self.modalStack
            );

            modalDialog.open(
                overlayContent,
                overlayHeader,
                {}
            );
            event.preventDefault();
        });


        /**
         * Switch favourite state via star icon
         */
        $('a[data-switch-favorites]').on('click', function (event) {
            var url = this.href;
            var onLogin = function () {
                document.location.href = url;
            };

            var onClose = function () {
                $(loginHandler).off('.view_switch');
            };

            if (!loginHandler.isLoggedIn()) {
                event.preventDefault();
                loginHandler.show();
                $(loginHandler).on('closed.view_switch', onClose);
                $(loginHandler).on('is-logged-in.view_switch', onLogin);
            }
        });


        /**
         * Show detail of paper inline in list
         */
        $('.show_cs_detail').on('click', function () {
            var altLabel = $(this).data('label-alt');
            var currentLabel = $(this).text();
            var detailWindow = $(this).next();

            $(this).data('label-alt', currentLabel);
            $(this).text(altLabel);

            if (detailWindow.is(':visible')) {
                detailWindow.addClass('hide');
            } else {
                detailWindow.removeClass('hide');
            }

            $(window).trigger('scroll');
        });



        /**
         * Topic and FoP filter
         */
        $("#tab_topic, #tab_kindofpresentation").on('click', function () {
            var childWrapper = 'topic_content';
            var offSet = '-800px';

            if (this.id == 'tab_kindofpresentation') {
                childWrapper = 'kindofpresentation_content';
                var offSet = '-420px';
            }

            if ($(this).hasClass('vertical_tab_bar_open')) {
                $(this).removeClass('vertical_tab_bar_open');
                $('.' + childWrapper).parent().animate({
                    left: offSet
                }, 'fast');
                $(this).html('&rsaquo;');
            } else {
                $(this).addClass('vertical_tab_bar_open');
                $('.' + childWrapper).parent().animate({
                    left: '0'
                }, 'fast');
                $(this).html('&lsaquo;');
            }
        });

        $('[data-filter-list]').each(
            function () {
                var listWrapper = this;
                var config = $(listWrapper).data('filter-list');
                var keyboardList = new List(listWrapper, config);

                $('#' + config.searchFieldId).on('keyup', function () {
                    var searchString = $(this).val();
                    keyboardList.search(searchString);
                });

                keyboardList.on('updated', function (keyboardList) {
                    var sectionHeading = $('#' + config.headingId);

                    if (keyboardList.visibleItems.length == 0) {
                        sectionHeading.hide();
                    } else {
                        sectionHeading.show();
                    }
                });
            }
        );

        $('#scrolled_viewport').on('scroll', function () {
            $(window).trigger('scroll');
        });

        window.setTimeout(function () {
            $('[data-src]').each(
                function () {
                    $(this).unveil({offset: 200});
                }
            );
        }, 1000);
    };

    return App;
});