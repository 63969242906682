/**
 * Converia Papersubmission App
 */
Converia.Core.module('Converia.Papersubmission.App', function () {
    var App = function (basicElement, config) {
        this.messageHandler = new Converia.Core.MessageHandler();
        this.messageHandler.pushMessages(config.messages || []);

        $("[data-modal]").click(function (event) {
            event.preventDefault();

            var modal = new Converia.Core.Modal();

            modal.open(this, {});
        });

        $("[data-latex-preview]").on("click", function (event) {
            event.preventDefault();

            var url = $(this).data("latex-preview");
            var val = $("[name^=textContent]").val();
            var textTypeLatex = Number($("#enableLatex").prop("checked"));

            url += "&content=" + encodeURIComponent(val);
            url += "&textTypeLatex=" + textTypeLatex;

            window.open(url, "_blank");
        }).removeClass("hide");


        // Hide or not to hide author related fields in general info form ?! --- ↓
        var checkIsAuthor = function () {
            if ($('#PsField_IsAuthor-1').prop('checked')) {
                $('[data-is-author-field=1]').show();
            } else {
                $('[data-is-author-field=1]').hide();
            }
        };

        checkIsAuthor();

        $("[name=PsField_IsAuthor]").on("click", checkIsAuthor);
        // --------------------------------------------------------------------- ↑

        $('[data-activate-wysiwyg]').tinymce({
            plugins: "wordcount,charcount",
            wordcount_cleanregex: /[.(),;:!?%#$?\x27\x22_+=\\\/\-]*/g,
            wordcount_countregex: /\S+/g,
            statusbar: true,
            script_url: '/build/admin/js/plugins/tinymce/tinymce.js',
            menubar: false,
            toolbar: "undo redo | bold italic | subscript superscript",
            language: config.languageKeyShortPart,
        });

        var companyList = config.companyList || [];

        $(basicElement).find('[data-autocomplete="company"]').each(
            function () {
                new Converia.Core.CompanyProposal(this, companyList);
            }
        );


        // var char_counter = function char_counter(int_len) {
        //     characterCount 	= $("#prg_content___").val();
        //     characterCount	= escape(characterCount);
        //     characterCount	= characterCount.replace(/%0A/g, "");
        //     characterCount	= characterCount.replace(/%0D/g, "");
        //     characterCount	= unescape(characterCount);
        //     int_len 		= characterCount.length;
        //
        //     $("#character-counter").text(int_len + "/'.(int)$intFormsize.'");
        //
        //     if (int_len > '.(int)$intFormsize.')	{
        //         $("#character-counter").addClass("warning");
        //     } else {
        //         $("#character-counter").removeClass("warning");
        //     }
        // }
    };

    return App;
    // @TODO: implement:
});
