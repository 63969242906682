/**
 * Converia Modal: iFrame specialized for the view of selectable list items with callback
 */
Converia.Core.module('Converia.Core.RelationListModal', function() {
    
    /**
     * RelationListModal
     *
     * @param modalStack
     * @constructor
     */
    var RelationListModal = function (modalStack) {
        Converia.Core.StructuredModal.call(
            this,
            modalStack
        );
    };

    /**
     * RelationListModal
     *
     * @type {Converia.Core.Modal}
     */
    RelationListModal.prototype = Object.create(
        Converia.Core.StructuredModal.prototype,
        {}
    );

    /**
     * @param relationEntities
     * @param relId
     * @param buttonLabel
     * @param noEntriesMessage
     * @returns {string}
     */
    var generateModalContentString = function (relationEntities, relId, buttonLabel, noEntriesMessage) {
        var contentTemplate = 
            '<div class="float-selection" data-rel-id="{{rel_id}}" id="floatsel_{{rel_id}}">' +
                '<div class="action-wrapper">' +
                    '<div class="row row-collapsed">' +
                        '<div class="column small-12">' +
                            '{{list}}' +
                        '</div>' +
                    '</div>' +
                '</div><!-- /.action-wrapper -->' +
                '<div class="overlay-footer">' +
                    '{{apply_button}}' +
                '</div>' +
            '</div>'
        ;
        
        var listTemplate = 
            '<div class="float-selection-wrapper">' +
                '<div class="float-select-list-wrapper">' +
                    '<table class="table float-selection-list" id="floatsel_categoryList_tbl">' +
                        '<tbody>' +
                            '{{list_items}}' +
                        '</tbody>' +
                    '</table><!-- /.float-selection-list -->' +
                '</div><!-- /.float-select-list-wrapper -->' +
            '</div><!-- /.float-selection-wrapper -->'
        ;
        
        var listItemTemplate = 
            '<tr id="categoryList_float_row_{{entity_id}}" data-type="float-list-item" data-value="{{entity_id}}">' +
                '<td class="col-name">' +
                    '{{entity_name}}' +
                '</td>' +
            '</tr>'
        ;
        
        var list        = '';
        var listItems   = '';
        var applyButton = '';        

        // create list string containig all list items
        for(var i = 0, len = relationEntities.length; i < len; i++) {
            var listItem = listItemTemplate;
            listItem = listItem.replace(/{{entity_id}}/g, relationEntities[i].id);
            listItem = listItem.replace(/{{entity_name}}/, relationEntities[i].name);
            listItems += listItem;
        }
        
        // if there are list items create the list and the apply button
        if(relationEntities.length){
            list = listTemplate.replace(/{{list_items}}/, listItems);  

            applyButton = 
                '<div class="button-control">' +
                    '<span class="button float-selection-button-apply-selection" title="' + buttonLabel + '">' +
                        buttonLabel +
                    '</span>' +
                '</div>'
            ;
        }else{
            list += noEntriesMessage;
        }
        
        var content = contentTemplate;
        content = content.replace(/{{rel_id}}/, relId);
        content = content.replace(/{{list}}/, list);
        content = content.replace(/{{apply_button}}/, applyButton);
        
        return content;
    };

    /**
     * @param title             The title for the modal
     * @param relationEntities  The selectable entities
     * @param options
     */
    RelationListModal.prototype.open = function(title, relationEntities, options) {
        
        var self = this;
        var innerContent = generateModalContentString(
            relationEntities,
            options['relId'],
            options['buttonLabel'],
            options['noEntriesMessage']
        );

        Converia.Core.StructuredModal.prototype.open.call(
            this,
            title,
            innerContent,
            '',
            options
        );
        
        var floatListItem = $(this.getModalDomElement()).find('[data-type="float-list-item"]');
        floatListItem.on(
            'click',
            function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                } else {
                    $(this).addClass('active');
                }
            });
        var button = $(this.getModalDomElement()).find('.float-selection-button-apply-selection');
        button.on(
            'click',
            function(){
                var clickedElements = floatListItem.filter('.active');
                var selectedIds = [];
                
                clickedElements.each(function(i, item) {
                    selectedIds.push(item.getAttribute('data-value'));
                });
                
                //getdata values
                $(self).trigger(
                    'elements-selected',
                    {
                        elements: selectedIds
                    }
                );
            }
        );
    };
    
    return RelationListModal;
});