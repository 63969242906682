Converia.Core.module('Converia.Core.ProcessIcon', function () {
    var ProcessIcon = function () {
        ProcessIcon.eleCount++;
        this.ident = 'component-process-' + (ProcessIcon.eleCount);
        this.ele = null;
        this.count = 0;
    };

    ProcessIcon.eleCount = 0;

    ProcessIcon.prototype.show = function (blocking) {
        var addClass = '';
        if (blocking) {
            addClass = ' process-wrapper-blocking';
        }
        this.count++;

        tpl = '' +
            '<div id="#id#" class="process-wrapper' + addClass + '">' +
            '   <div class="process-click-prevention-layer"></div>' +
            '   <div class="begui_ajax_processing begui_processing_active">' +
            '       <span class="spinner1-1"></span>' +
            '       <span class="spinner1-2"></span>' +
            '       <span class="spinner1-3"></span>' +
            '       <span class="spinner1-4"></span>' +
            '       <span class="spinner1-5"></span>' +
            '       </div>' +
            '</div>' +
        '';

        this.remove();
        this.ele = $('body').append(tpl.replace(/#id#/, this.ident));
    };

    ProcessIcon.prototype.hide = function () {
        this.count--;

        if (this.count <= 0) {
            this.remove();
            this.count = 0;
        }
    };

    ProcessIcon.prototype.remove = function () {
        this.ele = null;
        $('#' + this.ident).remove();
    };

    return ProcessIcon;
});